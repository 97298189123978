<!-- 其他状态列表 -->
<template>
  <div>
    <div class="order">
      <ul>
        <li
          class="inputBox"
          @click="
            $parent.checked = !$parent.checked;
            $parent.allChoice();
          "
        >
          <input
            class="headCheckBox"
            style="margin-right: 10px"
            type="checkbox"
            name=""
            id=""
            v-model="$parent.checked"
            @change="$parent.allChoice"
          />
          {{ $fanyi("全选") }}
        </li>
        <!-- <li class="goods">{{ $fanyi("产品") }}</li> -->
        <li class="detail">{{ $fanyi("属性") }}</li>
        <li class="num">
          <span>{{ $fanyi("单价") }}</span>
        </li>
        <!-- 数量 -->
        <li class="num">{{ $fanyi("单位") }}</li>
        <!-- 调查在库数量 -->
        <li
          class="price"
          v-if="
            ['obligation', 'OrderDetails', 'purchased', 'purchase'].indexOf(
              $parent.orderStatus
            ) != -1
          "
        >
          {{ $fanyi("调查在库数量") }}
        </li>
        <li
          class="num"
          v-if="
            ['obligation', 'OrderDetails', 'purchased', 'purchase'].indexOf(
              $parent.orderStatus
            ) != -1
          "
        >
          {{ $fanyi("在中国的总成本") }}
        </li>

        <!-- option说明界面 -->
        <li class="price" style="min-width:210px">
          {{ $fanyi("option说明界面") }}&nbsp;
          <img
            style="cursor: pointer;"
            @click="$fun.routerToPage('/servicios-adicionales', true)"
            :src="require('@/assets/icon/info_fill.svg')"
            alt=""
          />
        </li>
        <!-- 客户备注 -->
        <li class="price" style="min-width:210px">
          {{ $fanyi("观察") }}
        </li>
        <!-- 业务备注 -->
        <li class="price" style="min-width:210px">
          {{ $fanyi("代理响应") }}
        </li>
        <!-- 状态 -->
        <li class="price" v-if="$parent.orderStatus != 'obligation'">
          {{ $fanyi("状态") }}
        </li>
      </ul>
      <div
        class="goodsBox"
        :style="!$parent.showAllGoods ? 'max-height:300px' : ''"
      >
        <div v-for="(aitem, aindex) in $parent.newList" :key="aindex">
          <h1>
            <div
              class="checkedBox"
              @click="
                aitem.checked = !aitem.checked;
                $parent.shopAll(aindex);
              "
            >
              <input
                type="checkbox"
                name=""
                id=""
                v-model="aitem.checked"
                @change="$parent.shopAll(aindex)"
              />
            </div>
            {{ $fanyi("店铺") }}：{{
              aitem.shop_name_translate || aitem.shop_name
            }}
            &nbsp;
            <span
              class="logoFont"
              v-if="
                ['1688', 'taobao'].includes((aitem.list[0] || {}).from_platform)
              "
            >
              {{ (aitem.list[0] || {}).from_platform }}
            </span>
            <span v-else>
              <img
                class="rakuLogo"
                :src="require('@/assets/hometop/logo-rakumart-europe.svg')"
                alt=""
              />
            </span>
          </h1>
          <!-- {{ aitem.list }} -->
          <div class="goodsCon xiangqingCon">
            <div v-for="item in aitem.list" :key="item.id">
              <!-- 商品的显示行 -->
              <div>
                <ul class="goodsDetailCon">
                  <!-- 全选 -->
                  <li
                    class="inputBox"
                    @click="
                      item.checked = !item.checked;
                      $parent.radio(aindex);
                    "
                  >
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      @change="$parent.radio(aindex)"
                      v-model="item.checked"
                    />
                    <!-- 商品番号 -->
                    <span class="rowNumber">
                      <template
                        v-if="
                          $parent.orderStatus == 'obligation' &&
                            item.itemStatus != 'normalStatus'
                        "
                      >
                        <span
                          v-if="
                            [
                              'noNumquestionReStatus',
                              'questionReStatus',
                              'irreplaceableStatus',
                            ].includes(item.itemStatus)
                          "
                        >
                          <img
                            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66853469c9ccb.svg"
                            alt=""
                            class="iconBox"
                          />
                        </span>
                        <span v-else>
                          <img
                            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/669f95aa3a121.svg"
                            alt=""
                            class="iconBox"
                          />
                        </span>
                      </template>
                      <template v-else>
                        <span> {{ item.sorting }}</span>
                      </template>
                    </span>
                    <!-- <img :src="item.pic" alt="" /> -->
                    <el-popover placement="right" trigger="hover">
                      <img
                        :src="item.pic"
                        alt=""
                        style="width: 300px; height: 300px"
                      />

                      <img
                        @click="$fun.toCommodityDetails(item.goods_id)"
                        :src="item.pic"
                        alt=""
                        slot="reference"
                      />
                    </el-popover>

                    <!-- <img :src="item.pic" alt="item.goods_title" /> -->
                  </li>
                  <!-- 产品 -->
                  <!-- <li class="goods">
                    <el-popover placement="bottom" width="400" trigger="hover">
                      {{ item.goods_title }}
                      <div
                        class="showGoodsTitle"
                        :title="item.goods_title"
                        slot="reference"
                        @click="$fun.toCommodityDetails(item.goods_id)"
                      >
                        <font>{{ item.goods_title }}</font>
                      </div>
                    </el-popover>
                  </li> -->
                  <!-- 详细 -->
                  <li
                    class="detail"
                    :class="
                      item.detailWindow == true
                        ? 'active goodsDetail'
                        : 'goodsDetail'
                    "
                  >
                    <!-- 展示 -->

                    <el-popover placement="bottom" trigger="hover">
                      <div class="goodsDetailAll">
                        <div
                          class="goodsDetailAllOneBox"
                          v-for="(detailItem, detailIndex) in item.detail"
                          :key="detailIndex"
                          :title="detailItem.key + ':' + detailItem.value"
                        >
                          <h1 class="detailTitle">
                            {{ detailItem.key }}
                          </h1>
                          <p>
                            <span class="detailBody">{{
                              detailItem.value
                            }}</span>
                          </p>
                        </div>
                      </div>
                      <div
                        slot="reference"
                        class="showDetail"
                        v-if="
                          ['temporary', 'editing'].indexOf(
                            $parent.orderStatus
                          ) == -1
                        "
                      >
                        <p
                          v-for="(bitem, bindex) in item.detail"
                          :key="bitem.key + bindex"
                          :title="bitem.key + ':' + bitem.value"
                          class="goodsDetailOpt ispay"
                          @click.stop="
                            ['temporary', 'editing'].indexOf(
                              $parent.orderStatus
                            ) != -1
                              ? $parent.openDetailWindow(item)
                              : ''
                          "
                        >
                          {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                        </p>
                        <img
                          @click.stop="
                            ['temporary', 'editing'].indexOf(
                              $parent.orderStatus
                            ) != -1
                              ? $parent.openDetailWindow(item)
                              : ''
                          "
                          v-if="
                            ['temporary', 'editing'].indexOf(
                              $parent.orderStatus
                            ) != -1
                          "
                          :src="require('@/assets/preview_order/baianji.svg')"
                          alt=""
                        />
                      </div>
                    </el-popover>

                    <el-popover placement="right" trigger="click">
                      <div class="detailWindowBody">
                        <div
                          class="detailOpt"
                          v-for="(goodsGuiGeItem,
                          goodsGuiGeIndex) in item.pageSpecification"
                          :key="goodsGuiGeIndex"
                        >
                          <h1>{{ goodsGuiGeItem.key }}</h1>
                          <div class="optClass">
                            <span
                              v-for="(actGuige,
                              actIndex) in goodsGuiGeItem.value"
                              :key="actIndex"
                              :title="actGuige.name"
                              @click="
                                goodsGuiGeItem.active = actIndex;
                                $parent.goodsDetailChoice(
                                  item.detail[goodsGuiGeIndex],
                                  actGuige,
                                  item
                                );
                                $forceUpdate();
                              "
                              :class="
                                actIndex == goodsGuiGeItem.active
                                  ? 'active'
                                  : ''
                              "
                              >{{ actGuige.name }}</span
                            >
                          </div>
                        </div>
                        <!-- <div class="btnGroup">
                      <button>取消</button>
                      <button class="queRen">确认</button>
                    </div> -->
                      </div>

                      <!-- 可选择 -->
                      <div
                        slot="reference"
                        class="showDetail"
                        v-if="
                          ['temporary', 'editing'].indexOf(
                            $parent.orderStatus
                          ) != -1
                        "
                      >
                        <div class="showDetailCon">
                          <p
                            v-for="(bitem, bindex) in item.detail"
                            :key="bitem.key + bindex"
                            :title="bitem.key + ':' + bitem.value"
                            class="goodsDetailOpt ispay"
                          >
                            {{ $fun.trunUpperCase(bitem.key) }}:{{
                              bitem.value
                            }}
                          </p>
                        </div>

                        <img
                          @click.stop="
                            ['temporary', 'editing'].indexOf(
                              $parent.orderStatus
                            ) != -1
                              ? $parent.openDetailWindow(item)
                              : ''
                          "
                          v-if="
                            ['temporary', 'editing'].indexOf(
                              $parent.orderStatus
                            ) != -1
                          "
                          :src="require('@/assets/preview_order/baianji.svg')"
                          alt=""
                        />
                      </div>
                    </el-popover>
                  </li>

                  <!-- 单价 -->
                  <li class="price">
                    <b
                      >{{
                        $fun.EURNumSegmentation(
                          $fun.ceil(
                            (item.confirm_price && item.confirm_price > 0
                              ? item.confirm_price
                              : item.price) * $parent.datas.exchange_rate
                          )
                        )
                      }}€</b
                    >
                    ({{
                      $fun.RMBNumSegmentation(
                        item.confirm_price && item.confirm_price > 0
                          ? item.confirm_price
                          : item.price
                      )
                    }}￥)
                  </li>
                  <!-- 数量 -->
                  <li class="num">
                    <el-input-number
                      v-if="
                        ['temporary', 'editing'].indexOf($parent.orderStatus) !=
                          -1
                      "
                      v-model="item.num"
                      @change="$parent.getTotalPrice(item)"
                      :step="1"
                      :step-strictly="true"
                      size="mini"
                      :min="1"
                    ></el-input-number>
                    <span v-else> {{ item.num }} <br /> </span>
                  </li>
                  <!-- 调查在库数量 -->
                  <li
                    class="num"
                    v-if="
                      [
                        'obligation',
                        'OrderDetails',
                        'purchased',
                        'purchase',
                      ].indexOf($parent.orderStatus) != -1
                    "
                  >
                    {{ item.confirm_num }}
                  </li>
                  <!-- 在中国的总成本 -->
                  <li
                    class="num"
                    v-if="
                      [
                        'obligation',
                        'OrderDetails',
                        'purchased',
                        'purchase',
                      ].indexOf($parent.orderStatus) != -1
                    "
                  >
                    <div class="fdcolmun dip" style="gap: 5px;">
                      <div class="pricePerUnit">
                        <span>
                          <b
                            >{{
                              $fun.EURNumSegmentation(
                                item.china_amount.sum_amount
                              )
                            }}€</b
                          >
                        </span>
                        <span>
                          ({{
                            $fun.RMBNumSegmentation(
                              $fun.ceil(
                                item.china_amount.sum_amount /
                                  $parent.datas.exchange_rate
                              )
                            )
                          }}￥)
                        </span>
                      </div>
                      <el-popover
                        placement="bottom"
                        trigger="click"
                        popper-class="breakdownOfExpenses"
                      >
                        <div class="yuGuDaoShouFeiYongMingXi yugu">
                          <p>
                            <span class="title">
                              {{ $fanyi("产品成本") }}：
                            </span>
                            <span class="content"></span>
                            <b
                              >{{
                                $fun.EURNumSegmentation(
                                  item.client_buy_amount.length > 0
                                    ? item.client_buy_amount[
                                        $parent.xiBanYaYunFei(item)
                                      ].list.china_amount
                                    : ""
                                )
                              }}€</b
                            >
                            <span>
                              ({{
                                $fun.EURNumSegmentation(
                                  item.client_buy_amount.length > 0
                                    ? $fun.ceil(
                                        item.client_buy_amount[
                                          $parent.xiBanYaYunFei(item)
                                        ].list.china_amount /
                                          $parent.datas.exchange_rate
                                      )
                                    : ""
                                )
                              }})¥
                            </span>
                          </p>
                          <p>
                            <span class="title">
                              {{ $fanyi("国际运输") }}：
                            </span>
                            <span class="content">
                              <b
                                >{{
                                  $fun.EURNumSegmentation(
                                    item.client_buy_amount.length > 0
                                      ? item.client_buy_amount[
                                          $parent.xiBanYaYunFei(item)
                                        ].list.international_freight_amount
                                      : ""
                                  )
                                }}€</b
                              >
                              <span>
                                ({{
                                  $fun.EURNumSegmentation(
                                    item.client_buy_amount.length > 0
                                      ? $fun.ceil(
                                          item.client_buy_amount[
                                            $parent.xiBanYaYunFei(item)
                                          ].list.international_freight_amount /
                                            $parent.datas.exchange_rate
                                        )
                                      : ""
                                  )
                                }})¥
                              </span>
                            </span>
                          </p>
                          <p>
                            <span class="title">
                              {{ $fanyi("西班牙的运费") }}：
                            </span>
                            <span class="content">
                              <b
                                >{{
                                  $fun.EURNumSegmentation(
                                    item.client_buy_amount.length > 0
                                      ? item.client_buy_amount[
                                          $parent.xiBanYaYunFei(item)
                                        ].list.client_internal_amount
                                      : ""
                                  )
                                }}€</b
                              >
                              <span>
                                ({{
                                  $fun.EURNumSegmentation(
                                    item.client_buy_amount.length > 0
                                      ? $fun.ceil(
                                          item.client_buy_amount[
                                            $parent.xiBanYaYunFei(item)
                                          ].list.client_internal_amount /
                                            $parent.datas.exchange_rate
                                        )
                                      : ""
                                  )
                                }})¥
                              </span></span
                            >
                          </p>
                          <p>
                            <span class="title">
                              {{ $fanyi("反倾销关税") }}&nbsp;({{
                                item.client_buy_amount.length > 0
                                  ? item.client_buy_amount[
                                      $parent.xiBanYaYunFei(item)
                                    ].list.anti_dumping_duty_amount_rate
                                  : ""
                              }})：
                            </span>
                            <span class="content">
                              <b
                                >{{
                                  $fun.EURNumSegmentation(
                                    item.client_buy_amount.length > 0
                                      ? item.client_buy_amount[
                                          $parent.xiBanYaYunFei(item)
                                        ].list.anti_dumping_duty_amount
                                      : ""
                                  )
                                }}€</b
                              >
                              <span>
                                ({{
                                  $fun.EURNumSegmentation(
                                    item.client_buy_amount.length > 0
                                      ? $fun.ceil(
                                          item.client_buy_amount[
                                            $parent.xiBanYaYunFei(item)
                                          ].list.anti_dumping_duty_amount /
                                            $parent.datas.exchange_rate
                                        )
                                      : ""
                                  )
                                }})¥
                              </span></span
                            >
                          </p>
                          <p>
                            <span class="title">
                              {{ $fanyi("增值税") }}&nbsp;({{
                                item.client_buy_amount.length > 0
                                  ? item.client_buy_amount[
                                      $parent.xiBanYaYunFei(item)
                                    ].list.added_value_tax_amount_rate
                                  : ""
                              }})：
                            </span>
                            <span class="content">
                              <b
                                >{{
                                  $fun.EURNumSegmentation(
                                    item.client_buy_amount.length > 0
                                      ? item.client_buy_amount[
                                          $parent.xiBanYaYunFei(item)
                                        ].list.added_value_tax_amount
                                      : ""
                                  )
                                }}€</b
                              >
                              <span>
                                ({{
                                  $fun.EURNumSegmentation(
                                    item.client_buy_amount.length > 0
                                      ? $fun.ceil(
                                          item.client_buy_amount[
                                            $parent.xiBanYaYunFei(item)
                                          ].list.added_value_tax_amount /
                                            $parent.datas.exchange_rate
                                        )
                                      : ""
                                  )
                                }})¥
                              </span></span
                            >
                          </p>
                          <!-- <p
                            v-if="
                              item.client_buy_amount[
                                $parent.xiBanYaYunFei(item)
                              ] &&
                                item.client_buy_amount[
                                  $parent.xiBanYaYunFei(item)
                                ].list &&
                                item.client_buy_amount[
                                  $parent.xiBanYaYunFei(item)
                                ].list.personal_added_price
                            "
                          >
                            <span class="title">
                              {{ $fanyi("个人所得税") }}：
                            </span>
                            <span class="content">
                              <b
                                >{{
                                  $fun.EURNumSegmentation(
                                    item.client_buy_amount.length > 0
                                      ? item.client_buy_amount[
                                          $parent.xiBanYaYunFei(item)
                                        ].list.personal_added_price
                                      : ""
                                  )
                                }}€</b
                              >
                              <span>
                                ({{
                                  $fun.EURNumSegmentation(
                                    item.client_buy_amount.length > 0
                                      ? $fun.ceil(
                                          item.client_buy_amount[
                                            $parent.xiBanYaYunFei(item)
                                          ].list.personal_added_price /
                                            $parent.datas.exchange_rate
                                        )
                                      : ""
                                  )
                                }})¥
                              </span></span
                            >
                          </p> -->
                        </div>
                        <span class="mingXi" slot="reference" v-if="false">
                          {{ $fanyi("看到细节") }}
                        </span>
                      </el-popover>
                    </div>
                  </li>

                  <!-- option说明界面 -->
                  <li class="num options" style="min-width:210px">
                    <div class="optionInput">
                      <div
                        class="inputOption haveData"
                        @click="
                          $parent.setOrderOption(item);
                          openOptionDialog(item);
                        "
                      >
                        <p
                          class="list"
                          v-for="(eeitem, index) in item.option"
                          :key="index"
                        >
                          {{ eeitem.tag }}&nbsp;{{ eeitem.name_translate }}*{{
                            eeitem.num
                          }}
                        </p>
                      </div>
                    </div>
                  </li>
                  <!-- 客户备注 -->
                  <li class="num" style="min-width:210px">
                    <div class="autoHeight">
                      {{ item.client_remark }}
                    </div>
                  </li>
                  <!-- 业务备注 -->
                  <li class="num " style="min-width:210px">
                    <div class="autoHeight">
                      {{ item.y_reply }}
                    </div>
                  </li>
                  <!-- 状态 -->
                  <li class="num " v-if="$parent.orderStatus != 'obligation'">
                    <div class="autoHeight">{{ item.status_name }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="showAllGoods"
      :class="{ openBtn: $parent.showAllGoods }"
      v-if="$parent.numberOfStores.goods + $parent.replaceProductNum > 1"
    >
      <span @click="$parent.showAllGoods = !$parent.showAllGoods">{{
        $fanyi("部署")
      }}</span>
      <img
        class="jiahaoIcon"
        v-if="!$parent.showAllGoods"
        :src="require('@/assets/icon/jiahao_fill.svg')"
        @click="$parent.showAllGoods = !$parent.showAllGoods"
      />
      <img
        class="jiahaoIcon"
        v-else
        :src="require('@/assets/icon/jianhao.svg')"
        @click="$parent.showAllGoods = !$parent.showAllGoods"
      />
    </div>
    <optionDialog ref="OptionDialog" />
  </div>
</template>
<script>
import optionDialog from "./optionDialog.vue";
export default {
  data() {
    return {};
  },
  components: { optionDialog },
  computed: {},
  created() {},
  methods: {
    openOptionDialog(item) {
      this.$refs.OptionDialog.open(item);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
@import "../css/orderdetailCss";
</style>
