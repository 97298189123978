var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Array.isArray(_vm.porder_freight) && _vm.porder_freight.length)?_c('div',{staticClass:"modelMain"},[_c('div',{staticClass:"modelTitle"},[_c('h2',[_vm._v(_vm._s(_vm.$fanyi("包装清单详细信息")))]),(_vm.showButton)?_c('div',{staticClass:" showAllBtn",on:{"click":_vm.changeShowAllGoods}},[_c('span',[_vm._v(_vm._s(_vm.$fanyi("部署")))]),(!_vm.showAllGoods)?_c('img',{staticClass:"jiahaoIcon",attrs:{"src":require('@/assets/icon/jiahao_fill.svg')}}):_c('img',{staticClass:"jiahaoIcon",attrs:{"src":require('@/assets/icon/jianhao.svg')}})]):_vm._e()]),_vm._l((_vm.porder_freight),function(porder_freight_item,porder_freight_index){return _c('div',{key:porder_freight_index,staticClass:"oneGoodsBox"},[_c('div',{staticClass:"boxNumBox"},[_vm._v(" Número de caja:   "),_c('span',{staticClass:"numCor"},[_vm._v(_vm._s(porder_freight_item.number))])]),_c('table',{staticClass:"listTableHtmlBox"},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$fanyi("订单号")))]),_c('th',[_vm._v("NO")]),_c('th',[_vm._v(_vm._s(_vm.$fanyi("照片")))]),_c('th',[_vm._v(_vm._s(_vm.$fanyi("商品链接")))]),_vm._m(0,true),_c('th',[_vm._v(_vm._s(_vm.$fanyi("产品属性")))]),_c('th',[_vm._v(_vm._s(_vm.$fanyi("每包数量")))]),_c('th',[_vm._v(_vm._s(_vm.$fanyi("单价"))+" "),_c('br'),_vm._v("(€)")]),_c('th',[_vm._v(_vm._s(_vm.$fanyi("小计"))+" "),_c('br'),_vm._v("(€)")])]),_vm._l((porder_freight_item.porder_freight_detail),function(goods_item,goods_index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(goods_index + 1 <= 2 || porder_freight_item.showAllGoods),expression:"goods_index + 1 <= 2 || porder_freight_item.showAllGoods"}],key:goods_index},[_c('td',[_c('div',{staticClass:"infoBox",staticStyle:{"width":"160px"}},[_c('span',{staticClass:"aLink",on:{"click":function($event){_vm.$fun.routerToPage(
                  '/OrderDetails?type=' +
                    'peisong' +
                    '&order_sn=' +
                    (goods_item.order_detail || {}).order_sn,
                  true
                )}}},[_vm._v(" "+_vm._s((goods_item.order_detail || {}).order_sn)+" ")])])]),_c('td',[_c('div',{staticClass:"infoBox",staticStyle:{"width":"50px"}},[_vm._v(_vm._s(goods_index + 1))])]),_c('td',[_c('div',{staticClass:"infoBox"},[_c('el-popover',{attrs:{"placement":"right","trigger":"hover"}},[_c('img',{staticStyle:{"width":"300px","height":"300px"},attrs:{"src":(goods_item.order_detail || {}).pic,"alt":""}}),_c('el-image',{attrs:{"slot":"reference","src":(goods_item.order_detail || {}).pic},on:{"click":function($event){_vm.$fun.toCommodityDetails(
                    (goods_item.order_detail || {}).goods_id
                  )}},slot:"reference"},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error","src":(goods_item.order_detail || {}).pic},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})]),_c('div',{attrs:{"slot":"placeholder"},slot:"placeholder"},[_c('i',{staticClass:"el-icon-loading"})])])],1)],1)]),_c('td',[_c('div',{staticClass:"infoBox",staticStyle:{"width":"200px"}},[_c('span',{staticClass:"aLink",on:{"click":function($event){_vm.$fun.toCommodityDetails(
                  (goods_item.order_detail || {}).goods_id
                )}}},[_vm._v(" "+_vm._s(_vm.host)+"/ProductDetails?goods_id="+_vm._s((goods_item.order_detail || {}).goods_id))])])]),_c('td',[_c('div',{staticClass:"infoBox",staticStyle:{"width":"180px"}},[_c('el-popover',{attrs:{"placement":"right","width":"400","trigger":"click"}},[_vm._v(" "+_vm._s((goods_item.order_detail || {}).goods_title)+" "),_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_c('span',{staticClass:"goodsTitleBox",staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s((goods_item.order_detail || {}).goods_title)+" ")])])])],1)]),_c('td',[_c('div',{staticClass:"infoBox"},[_c('el-popover',{attrs:{"placement":"bottom","trigger":"hover"}},[_c('div',{staticClass:"goodsDetailAll"},_vm._l(((
                    goods_item.order_detail || {}
                  ).detail),function(detailItem,detailIndex){return _c('div',{key:detailIndex,staticClass:"goodsDetailAllOneBox",attrs:{"title":detailItem.key + ':' + detailItem.value}},[_c('h1',{staticClass:"detailTitle"},[_vm._v(_vm._s(detailItem.key))]),_c('p',[_c('span',{staticClass:"detailBody"},[_vm._v(_vm._s(detailItem.value))])])])}),0),_c('div',{staticClass:"detailOptBox",staticStyle:{"width":"208px"},attrs:{"slot":"reference"},slot:"reference"},_vm._l(((
                    goods_item.order_detail || {}
                  ).detail),function(detailItem,detailIndex){return _c('p',{key:detailIndex,staticClass:"detailOpt",attrs:{"title":detailItem.key + ':' + detailItem.value}},[_vm._v(" "+_vm._s(detailItem.key)+" :"+_vm._s(detailItem.value)+" ")])}),0)])],1)]),_c('td',[_c('div',{staticClass:"infoBox",staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s((goods_item || {}).num)+" ")])]),_c('td',[_c('div',{staticClass:"infoBox",staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(_vm.$fun.RMBNumSegmentation(_vm.$fun.ceil(goods_item.price)))+" ")])]),_c('td',[_c('div',{staticClass:"infoBox"},[_vm._v(" "+_vm._s(_vm.$fun.RMBNumSegmentation(_vm.$fun.ceil(goods_item.total)))+" ")])])])})],2),_c('div',{staticClass:"tableBottomInfoBox"},[_c('div',{staticClass:"flex acenter gap45"},[_c('div',{staticClass:"logisticsInfoBox"},[_c('label',[_vm._v(_vm._s(_vm.$fanyi("重量（Kg）"))+": ")]),_c('span',[_vm._v(_vm._s(porder_freight_item.weight))])]),_c('div',{staticClass:"logisticsInfoBox"},[_c('label',[_vm._v(_vm._s(_vm.$fanyi("体积"))+"(m³): ")]),_c('span',[_vm._v(_vm._s(_vm.$fun.ceil(porder_freight_item.volume)))])]),_c('div',{staticClass:"logisticsInfoBox"},[_c('label',[_vm._v(_vm._s(_vm.$fanyi("长度"))+"(cm): ")]),_c('span',[_vm._v(_vm._s(porder_freight_item.length))])]),_c('div',{staticClass:"logisticsInfoBox"},[_c('label',[_vm._v(_vm._s(_vm.$fanyi("宽度"))+"(cm): ")]),_c('span',[_vm._v(_vm._s(porder_freight_item.width))])]),_c('div',{staticClass:"logisticsInfoBox"},[_c('label',[_vm._v(_vm._s(_vm.$fanyi("高度"))+"(cm): ")]),_c('span',[_vm._v(_vm._s(porder_freight_item.height))])])]),(
          porder_freight_item.porder_freight_detail &&
            porder_freight_item.porder_freight_detail.length > 2
        )?_c('div',{staticClass:" showAllBtn",on:{"click":function($event){porder_freight_item.showAllGoods = !porder_freight_item.showAllGoods;
          _vm.$forceUpdate();}}},[_c('span',[_vm._v(_vm._s(_vm.$fanyi("部署")))]),(!porder_freight_item.showAllGoods)?_c('img',{staticClass:"jiahaoIcon",attrs:{"src":require('@/assets/icon/jiahao_fill.svg')}}):_c('img',{staticClass:"jiahaoIcon",attrs:{"src":require('@/assets/icon/jianhao.svg')}})]):_vm._e()])])})],2):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_vm._v(" Nombre del "),_c('br'),_vm._v(" producto en español ")])}]

export { render, staticRenderFns }