<!-- 切换订单和配送单组件 -->
<template>
  <div class="tabs">
    <div class="tab" :class="{ active: value }" @click="$emit('input', true)">
      <div>
        Detalles del pedido
      </div>
    </div>
    <div class="tab" :class="{ active: !value }" @click="$emit('input', false)">
      <div>
        Pedido de entrega &nbsp;<span class="count">
          ({{ porder_sn_set.length || 0 }})
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean, // v-model 绑定的值
  },
  data() {
    return {};
  },
  components: {},
  computed: {
    porder_sn_set() {
      return this.$parent.porder_sn_set;
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.tabs {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  width: fit-content;
  font-family: Arial, sans-serif;
}

.tab {
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  &:first-child {
    padding-right: 0;
  }
  &:last-child {
    padding-left: 0;
  }
  div {
    @extend .dip;
    width: 160px;
    height: 40px;
    border-radius: 6px;
  }
}

.tab.active {
  div {
    background-color: #e8f0fe;
  }
}

.count {
  color: #1e2997;
}
</style>
