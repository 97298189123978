<template>
  <div
    class="opage"
    element-loading-text=""
    element-loading-background="rgba(0, 0, 0, 0.3)"
    v-loading.fullscreen.lock="fullscreenLoading"
  >
    <div class="DeliveryDetails">
      <HomePageTop v-if="!componentPattern" />
      <Dialog ref="dialog" :config="config" />
      <el-dialog title="" width="1200px" :visible.sync="showAdd">
        <addlist v-if="showAdd" />
      </el-dialog>

      <div class="showdow porderInfoBox flex acenter jsb">
        <div v-if="!componentPattern">
          <p class="t2">{{ $fanyi("付款详情") }}</p>
          <p class="snP">{{ $fanyi("交货订单") }} : {{ porder_sn }}</p>
        </div>
        <div v-else class="porderSnArrBox ">
          <p class="snP acenter">
            <span class="let2">{{ $fanyi("交货订单") }}&nbsp;:</span>
            <span
              v-for="(sn_item, sn_index) in modeluse_porder_sn"
              :key="sn_index"
              :class="{ active: sn_item == porder_sn }"
              @click="
                porder_sn = sn_item;
                getData();
              "
              >{{ sn_item }}</span
            >
          </p>
        </div>

        <div class="flex acenter">
          <span class="red">{{
            $fanyi("注意：如果您想更改交货订单，请与我们联系。")
          }}</span>

          <el-button class="bgBlueBtn" @click="downLoadPackIngXlx">
            {{ $fanyi("下载订购交货") }}</el-button
          >
        </div>
      </div>

      <!-- v-if="['临时保存', '报价中'].indexOf(pageStatus) == -1" -->
      <dcostInfomation />
      <!-- 费用信息栏 -->
      <!-- <customsClearanceModeInformation /> -->
      <dgoodsInfo
        v-if="['临时保存', '报价中'].indexOf(pageStatus) == -1"
        ref="DgoodsInfo"
      />

      <boxAndGoodsInfo />

      <!-- 订单重量信息、商品信息等 -->
      <!-- 分割线 -->
      <hr class="feiYongDizhi" ref="myreffeiyongeee" v-if="false" />

      <customerPrompt
        ref="customerPrompt"
        style="background: transparent; box-shadow: none; height: 0px"
      >
      </customerPrompt>
    </div>

    <!-- 优惠券 -->
    <Coupons ref="couponsPage" />
  </div>
</template>
<script>
import HomePageTop from "../../../../../../components/head/HomePageTop.vue";
import addlist from "../../../warehouse/index.vue";
import dgoodsInfo from "./components/dgoodsInfo.vue";
import Dialog from "../../../../../../components/public/Dialog.vue";
import dcostInfomation from "./components/dcostInfomation.vue";
import customerPrompt from "./components/customerPrompt";
import FootVue from "../../../../../../components/foot/Foot.vue";
import Coupons from "./components/Coupons.vue";
import customsClearanceModeInformation from "./components/customsClearanceModeInformation.vue";
import boxAndGoodsInfo from "./components/boxAndGoodsInfo.vue";
import urlHead from "@/api/wangZhi.js";
export default {
  props: ["modeluse_porder_sn"],
  data() {
    return {
      datas: { result: {} }, //所有数据
      tableData: [], //上面表格数据
      orderData: null, //配送单详情页面下面表格数据
      checked: false, //全选
      exchange_rate: 0, //当时汇率
      youHuiQuanShiYong: false, //使用优惠券或代金券判断
      daijinSmall: false, //使用优惠券判断
      youhuiSmall: false, //使用优惠券判断
      CouponsShow: false, //使用优惠券弹窗
      discountAmount: 0,
      CouponsData: {
        ids: "",
        str: "",
      }, // 优惠券使用数据
      pageStatus: "待发货", //页面状态，参数：临时保存、报价中、等待付款、待发货
      // 接口请求条件
      form: {
        ids: this.$route.query.id,
        personal_clearance: "yes",
        logistics: "ocs",
        client_remark: "",
      },
      // 订单其他信息
      orderData: {
        shangPinZhongLei: 0, //商品种类
        shuLiangZongJi: 0, //数量总计
        zongJia: 0, //总价(人民币)
      },
      // 配送单号
      porder_sn: "",

      //选择的地址名字
      userActiveAddName: {
        importer: "",
        consignee: "",
      },
      //选择的地址详细信息
      userActiveAdd: {
        importer: {
          user_id: 1,
          type: 1,
          company: "",
          zip_code: "",
          address: "",
          contacts: "",
          mobile: "",
          tel: "",
          mark: "",
          email: "",
          cnpj: "",
        },
        consignee: {
          user_id: 1,
          type: 2,
          company: "",
          zip_code: "",
          address: "",
          contacts: "",
          mobile: "",
          tel: "",
          mark: "",
          email: "",
          cnpj: "",
        },
      },
      //用户地址列表
      useraddLists: {},
      wuLiuOptions: [], //物流方式列表
      showAdd: false, //是否显示添加商品弹窗
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("删除"), this.$fanyi("取消")],
      },
      porder_freight: [],
      componentPattern: false, //是否是组件模式
      fullscreenLoading: false,

      statusList: [
        { key: "已取消", value: 1 },
        { key: "临时保存", value: 10 },
        { key: "报价中", value: 20 },
        { key: "报价中", value: 21 },
        { key: "报价中", value: 22 },
        { key: "等待付款", value: 30 },
        { key: "已付款待确认", value: 40 },
        { key: "待发货", value: 50 },
        { key: "待发货", value: 51 },
        { key: "待发货", value: 52 },
        { key: "待发货", value: 53 },
        { key: "已发货", value: 60 },
        { key: "已通关", value: 62 },
        { key: "西班牙国内配送中", value: 64 },
        { key: "已签收", value: 70 },
      ],
    };
  },
  components: {
    HomePageTop,
    dgoodsInfo,
    addlist,
    dcostInfomation,
    Dialog,
    customerPrompt,
    FootVue,
    customsClearanceModeInformation,
    Coupons,
    boxAndGoodsInfo,
  },
  computed: {
    gerenshui() {
      // invoice_type: 1=企业,2=个人
      if (this.datas.result.invoice_type == 2) {
        return true;
      }
      return false;
    },
  },
  created() {
    //如果modeluse_porder_sn有传入代表是作为组件使用的,否则取路由上的配送单号
    console.log(
      Array.isArray(this.modeluse_porder_sn) && this.modeluse_porder_sn.length,
      this.$route.query.order_sn
    );
    if (
      Array.isArray(this.modeluse_porder_sn) &&
      this.modeluse_porder_sn.length
    ) {
      this.porder_sn = this.modeluse_porder_sn[0];
      this.componentPattern = true;
    } else {
      this.porder_sn = this.$route.query.order_sn;
      this.componentPattern = false;
      this.$forceUpdate();
    }
    // 获取数据
    this.getData();
    // 获取用户地址
    this.useraddressList();
    // 获取物流方式列表
    this.getwuLiu();
  },
  methods: {
    // 更改通关方式
    changCustomsClearanceWay() {
      if (this.form.personal_clearance == "yes") {
        //console.log(this.datas);
        this.userActiveAdd.importer = this.datas.importer_address;
      } else {
        this.userActiveAdd.importer = {
          address: "",
          cnpj: "",
          company: "",
          contacts: "",
          email: "",
          id: 2,
          mark: "",
          mobile: "",
          tel: "",
          type: 1,
          user_Id: 0,
          zip_code: "",
        };
        if (
          this.useraddLists.importer &&
          ["临时保存"].indexOf(this.pageStatus) != -1
        ) {
          this.useraddLists.importer.forEach((addListItem) => {
            if (addListItem.acquiescent) {
              this.userActiveAddName.importer = addListItem;
              this.findValue(addListItem.id, "importer");
            }
          });
        }
      }
    },
    // 获取数据
    getData() {
      this.fullscreenLoading = true;
      if (this.$route.query.order_sn) {
        this.$api
          .storageDeliverDetail({ porder_sn: this.porder_sn })
          .then((res) => {
            this.fullscreenLoading = false;

            //console.log("storageDeliverDetail", res);
            if (res.code != 0) return;

            this.porder_sn = res.data.porder_sn;

            this.datas = res.data;
            this.exchange_rate = res.data.result.exchange_rate;
            this.porder_freight = res.data.result.porder_freight;
            //console.log("fds", this.datas);
            this.pageStatus = res.data.result.status_name;
            if (this.pageStatus == "临时配送单") {
              this.pageStatus = "临时保存";
            }

            // 根据状态修改tag标签
            if (["临时保存", "报价中"].includes(this.pageStatus)) {
              this.$store.commit(
                "changeTagTitle",
                "Rakumart - Orden de entrega para cotización"
              );
            } else if (this.pageStatus == "等待付款") {
              this.$store.commit(
                "changeTagTitle",
                "Rakumart - Orden de entrega pendiente de pago"
              );
            } else if (this.pageStatus == "待发货") {
              this.$store.commit(
                "changeTagTitle",
                "Rakumart - Orden de entrega en espera de ser enviada"
              );
            } else if (["已发货", "已通关"].includes(this.pageStatus)) {
              this.$store.commit(
                "changeTagTitle",
                "Rakumart - Orden de entrega enviada"
              );
            } else if (this.pageStatus == "西班牙国内配送中") {
              this.$store.commit(
                "changeTagTitle",
                "Rakumart - Entrega en España"
              );
            } else if (this.pageStatus == " 已签收") {
              this.$store.commit(
                "changeTagTitle",
                "Rakumart - Orden de entrega recibida"
              );
            }

            // 如果状态大于已发货, 就默认展示第一个箱子的物流轨迹
            if (
              this.$fun.findNo(this.statusList, this.pageStatus) >=
              this.$fun.findNo(this.statusList, "已发货")
            ) {
              this.$refs.DgoodsInfo.getlogisticsInformation(
                this.datas.result.express_no
              );
            }

            // 备注
            this.form.client_remark = res.data.result.client_remark;
            // 地址
            if (res.data.result.importer) {
              this.userActiveAdd.importer = res.data.result.importer; //进口商
            }
            if (res.data.result.receive) {
              this.userActiveAdd.consignee = res.data.result.receive; //收件人
            }

            // 通关方式
            if (res.data.result.personal_clearance_name == "rakumart代通关") {
              this.form.personal_clearance = "yes";
            } else if (res.data.result.personal_clearance_name == "个人通关") {
              this.form.personal_clearance = "no";
            }

            this.tableData = this.datas.result.porder_detail;
            //console.log(this.tableData);
            // 调用弹出提示函数
            this.$refs.customerPrompt.openOrderDia();
            this.detailDataTreating();
          });
      } else if (this.$route.query.id) {
        this.pageStatus = "临时保存";
        this.$api
          .storageSendDeliverPreview({ ids: this.form.ids })
          .then((res) => {
            this.fullscreenLoading = false;
            //console.log("storageSendDeliverPreview", res);
            if (res.code != 0) return;
            this.datas = res.data;
            //console.log(res.data.result);
            this.porder_sn = res.data.porder_sn;
            this.tableData = res.data.result;
            // 进口商地址默认赋值
            this.userActiveAdd.importer = this.datas.importer_address;
            // 调用弹出提示函数
            this.$refs.customerPrompt.openOrderDia();
            this.dataTreating();
          });
      }
    },
    // 计算商品种类和数量总计
    setOrderNum() {
      //console.log("werwe", this.orderData);
      this.orderData = {
        shangPinZhongLei: 0, //商品种类
        shuLiangZongJi: 0, //数量总计
        zongJia: 0, //总价(人民币)
      };
      this.tableData.forEach((orderItem) => {
        orderItem.order_detail.forEach((goodsItem) => {
          this.orderData.shangPinZhongLei++;
          this.orderData.shuLiangZongJi += Number(goodsItem.submitNum);
        });
      });
      //console.log("werwe", this.orderData);
    },
    // 配送单详情处理数据
    detailDataTreating() {
      //console.log(this.tableData);
      this.orderData = {
        shangPinZhongLei: 0, //商品种类
        shuLiangZongJi: 0, //数量总计
        zongJia: 0, //总价(人民币)
      };
      this.tableData.forEach((orderItem) => {
        orderItem.showGoodsList = true;
        orderItem.checked = false;
        orderItem.order_detail = [orderItem.order_detail];
        orderItem.order_detail.forEach((goodsItem) => {
          this.orderData.shangPinZhongLei++;
          this.orderData.shuLiangZongJi += Number(goodsItem.submitNum);
          goodsItem.totalPrice = this.$fun.ceil(
            goodsItem.confirm_num * goodsItem.confirm_price
          );
          goodsItem.checked = false;
        });
      });

      //费用总计= 其他费用+国际物流费用+西班牙物流费用+增值税+反倾销税，因为购买商品的费用在之前已经支付过了
      this.orderData.zongJia =
        Number(this.datas.result.other_price) +
        Number(this.datas.result.logistics_price) +
        Number(this.datas.result.foreign_express_price) +
        Number(this.datas.result.added_price) +
        Number(this.datas.result.clearance_price) +
        (this.gerenshui ? Number(this.datas.result.personal_added_price) : 0);
    },
    // 配送单提出预览处理数据
    dataTreating() {
      this.orderData = {
        shangPinZhongLei: 0, //商品种类
        shuLiangZongJi: 0, //数量总计
        zongJia: 0, //总价(人民币)
      };
      this.tableData.forEach((orderItem) => {
        orderItem.showGoodsList = true;
        orderItem.checked = false;
        orderItem.order_detail.forEach((goodsItem) => {
          this.orderData.shangPinZhongLei++;
          this.orderData.shuLiangZongJi += Number(goodsItem.submitNum);
          this.orderData.zongJia += this.$fun.ceil(
            goodsItem.confirm_num * goodsItem.confirm_price
          );
          goodsItem.totalPrice = Number(
            this.$fun.ceil(goodsItem.confirm_num * goodsItem.confirm_price)
          );
          goodsItem.checked = false;
        });
      });
    },
    getwuLiu() {
      this.$api.internationalLogisticsTransportation().then((res) => {
        //console.log("internationalLogisticsTransportation", res);
        if (res.code != 0) return;
        if (res.data.length == 0) return false;
        this.wuLiuOptions = res.data;
        this.form.logistics = this.wuLiuOptions[0].name;
      });
    },
    // 单选
    oddChoice(i) {
      let flag = this.tableData[i].order_detail.every(
        (item) => item.checked == true
      );
      if (flag) {
        this.tableData[i].checked = true;
      } else {
        this.tableData[i].checked = false;
      }
      this.isCheckedAll();
      this.$forceUpdate();
      //   flag ? (this.tableData[i].checked = true) : (this.tableData[i].checked = false);
    },
    // 店铺全选
    shopAll(i) {
      if (this.tableData[i].checked) {
        this.tableData[i].order_detail.forEach((item) => {
          item.checked = true;
        });
      } else {
        this.tableData[i].order_detail.forEach((item) => {
          item.checked = false;
        });
      }
      this.$forceUpdate();
      this.isCheckedAll();
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.tableData.forEach((item, index) => {
          item.checked = true;
          this.shopAll(index);
        });
      } else {
        this.tableData.forEach((item, index) => {
          item.checked = false;
          this.shopAll(index);
        });
      }
      this.$forceUpdate();
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.tableData.every((item, index) => item.checked == true);
      if (flag == true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    // 临时保存或提交订单并跳转到配送单列表页面
    storageSendDeliver(type) {
      if (type == "formal") {
        // 收货地址
        for (let i in this.userActiveAdd.consignee) {
          //console.log(this.userActiveAdd.consignee[i]);
          if (
            [
              "type",
              "company",
              "zip_code",
              "address",
              "contacts",
              "mobile",
              "tel",
              "email",
              "cnpj",
            ].indexOf(i) != -1 &&
            !this.userActiveAdd.consignee[i]
          ) {
            this.$fun.maoDian(this.$refs.myreffeiyongeee);
            return this.$message(this.$fanyi("提出配送单请将地址填写完整"));
          }
        }
        let url = this.$route;
        //console.log();
        // 进口商
        for (let i in this.userActiveAdd.importer) {
          if (
            [
              "type",
              "company",
              "zip_code",
              "address",
              "contacts",
              "mobile",
              "tel",
              "email",
              "cnpj",
            ].indexOf(i) != -1 &&
            !this.userActiveAdd.importer[i]
          ) {
            this.$fun.maoDian(this.$refs.myreffeiyongeee);
            return this.$message(this.$fanyi("提出配送单请将地址填写完整"));
          }
        }
      }
      let ids = [];
      this.tableData.forEach((orderItem) => {
        delete orderItem.showGoodsList;
        delete orderItem.checked;

        orderItem.order_detail.forEach((goodsItem) => {
          delete goodsItem.totalPrice;
          delete goodsItem.checked;
          ids.push(goodsItem.id);
        });
      });
      if (ids.length == 0) {
        return this.$message(this.$fanyi("请至少添加一个商品"));
      }
      let datas = {
        submitStatus: type,
        porder_sn: this.porder_sn,
        ids: ids.join(","),
        data: JSON.stringify(this.tableData),
        logistics: this.form.logistics,
        personal_clearance:
          this.form.personal_clearance == "yes"
            ? "no"
            : this.form.personal_clearance == "no"
            ? "yes"
            : this.form.personal_clearance,
        client_remark: this.form.client_remark,
      };
      // 假如是个人通关则传递地址
      // if (this.form.personal_clearance == "no") {
      datas.consignee_address = JSON.stringify(this.userActiveAdd.consignee);
      datas.importer_address = JSON.stringify(this.userActiveAdd.importer);
      // }
      //console.log(datas.consignee_address);
      if (
        this.form.personal_clearance == "no" &&
        datas.consignee_address == {} &&
        datas.importer_address == {}
      ) {
        return this.$message(this.$fanyi("通关方式为个人通关，请填写地址信息"));
      }
      this.$api.storageSendDeliver(datas).then((res) => {
        //console.log("storageSendDeliver", res);
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        // 如果是临时保存，跳转到配送单列表临时保存的状态
        if (type != "formal") {
          this.$fun.routerToPage("/user/deliveryList?status=temporary");
        } else {
          this.$fun.routerToPage("/user/deliveryList");
        }
      });
    },
    // 将指定id的对象填充到地址框
    findValue(e, type) {
      //console.log(e);
      let items = this.useraddLists[type].find((item) => item.id == e);
      this.userActiveAdd[type] = items;
      // //console.log(items);
    },
    // 获取用户所有地址
    useraddressList() {
      this.$api.useraddressList().then((res) => {
        if (res.code != 0) return false;
        this.useraddLists = res.data;
        //console.log("用户所有地址", this.userActiveAdd.consignee);
        // 检查用户是否有修改过收货地址
        let notNull = false;
        for (let consiitem in this.userActiveAdd.consignee) {
          if (
            ["id", "mark", "type", "user_Id"].indexOf(
              this.userActiveAdd.consignee[consiitem]
            ) == -1 &&
            this.userActiveAdd.consignee[consiitem]
          ) {
            notNull = true;
          }
        }

        // 如果收货列表存在并且配送单状态是临时保存并且用户未填写地址时匹配默认地址,另外一个页面打开的时候不需要匹配，因为默认是代通关，如果个人通关用户一定填了地址
        if (
          this.useraddLists.consignee &&
          ["临时保存"].indexOf(this.pageStatus) != -1 &&
          notNull == true
        ) {
          this.useraddLists.consignee.forEach((addListItem) => {
            if (addListItem.acquiescent) {
              this.userActiveAddName.consignee = addListItem;
              this.findValue(addListItem.id, "consignee");
            }
          });
        }
      });
    },
    // 限制提出数量不能大于可提出数
    setPutNum(item) {
      if (item.totalWaitSubmitNum < item.submitNum) {
        item.submitNum = item.totalWaitSubmitNum;
        this.$message(this.$fanyi("提出数不能超过可提出数"));
      }
    },
    // 删除商品
    delData() {
      let iddff = false;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].checked == true) {
          iddff = true;
          break;
        }
        for (let j = 0; j < this.tableData[i].order_detail.length; j++) {
          if (this.tableData[i].order_detail[j].checked == true) {
            iddff = true;
            break;
          }
        }
      }
      //console.log(123231);
      if (iddff == false) {
        return this.$message.warning(this.$fanyi("未选择操作的商品"));
      }
      this.$refs.dialog.open(
        this.$fanyi("是否确认删除？"),
        () => {
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].checked == true) {
              this.tableData.splice(i, 1);
              i--;
              continue;
            }

            for (let j = 0; j < this.tableData[i].order_detail.length; j++) {
              if (this.tableData[i].order_detail[j].checked == true) {
                this.tableData[i].order_detail.splice(j, 1);
                j--;
              }
            }
          }
          this.dataTreating();
        },
        () => {}
      );
    },
    // 前往付款页面
    goPay() {
      // return console.log(this.CouponsData.ids);
      this.$fun.routerToPage(
        "/payment?porder_sn=" +
          this.porder_sn +
          "&peiSong=true" +
          "&coupon_ids=" +
          this.CouponsData.ids
      );
    },
    // 添加数据
    addGoods(goodsData) {
      goodsData.forEach((orderItem) => {
        if (
          this.tableData.filter((item) => item.order_sn == orderItem.order_sn)
            .length != 0
        ) {
          let index = this.tableData.indexOf(
            this.tableData.find((item) => item.order_sn == orderItem.order_sn)
          );
          //console.log(index);
          orderItem.order_detail.forEach((goodsItem) => {
            this.tableData[index].order_detail.push(goodsItem);
          });
        } else {
          this.tableData.push(orderItem);
        }
      });
      this.dataTreating();
      this.showAdd = false;
    },
    downLoadPackIngXlx() {
      let url = `${urlHead}/client/download.packingList?porder_sn=${this.porder_sn}`;

      let a = document.createElement("a");
      a.target = "_blank";
      a.href = url;

      a.click();
      document.removeChild(a);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
@import "./css/index.scss";
</style>
