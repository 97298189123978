<template>
  <div class="SpecialNoteVue">
    <!-- {{ address_type }}sdaf{{ userChiose }} -->
    <div class="modelTitleAndequivalentSurcharge">
      <div class="moTitle">
        {{ $fanyi("选择或添加送货地址") }}
      </div>
      <!-- 这里说不展示并且默认选否 -->
      <div class="chioseBox" v-if="false">
        <span
          :class="{
            readOnly: !cantEdit,
          }"
          @click="$parent.doesCompanyPayTax = !$parent.doesCompanyPayTax"
        >
          {{ $fanyi("等值附加费") }}(5,2%)
        </span>
        <el-popover placement="top-start" trigger="click">
          <div class="optionHints">
            <div class="con">
              <h1>{{ $fanyi("等值附加费") }}</h1>
              <p>
                {{
                  $fanyi(
                    "如果你是在特别等同附加费制度下纳税的，请在购买发票上的方框内打勾。"
                  )
                }}
              </p>
            </div>
          </div>
          <img
            slot="reference"
            :src="require('@/assets/icon/info_fill.svg')"
            alt=""
          />
        </el-popover>
        <el-button
          :class="{
            active: $parent.doesCompanyPayTax == false,
            readOnly: !cantEdit,
            error: equivalentSurchargeError,
          }"
          @click="chioseequivalentSurcharge(false)"
        >
          No
        </el-button>
        <el-button
          :class="{
            active: $parent.doesCompanyPayTax == true,
            readOnly: !cantEdit,
            error: equivalentSurchargeError,
          }"
          @click="chioseequivalentSurcharge(true)"
        >
          Sí
        </el-button>
      </div>
    </div>
    <div class="SpecialNoteVueCon">
      <div class="addressSelection">
        <!-- 左侧选择框 -->
        <div class="addressChioseList" :class="{ optError: addressError }">
          <el-collapse accordion v-model="openelCollapse">
            <el-collapse-item>
              <template slot="title">
                <div class="addressChioseOpt">
                  <div class="imgBox">
                    <img
                      style="width: 20px;height: 25px;"
                      :src="require('@/assets/icon/xuanfoi.png')"
                      alt=""
                    />
                  </div>
                  <span>
                    Mis direcciones
                  </span>
                </div>
              </template>
              <div class="customAddressListBox">
                <div
                  class="customAddressOptBox"
                  :class="{ readOnly: !cantEdit, activeOpt: item.checked }"
                  v-for="(item, index) in userAddressList"
                  :key="index"
                  @click="chioseThis(item, index)"
                >
                  <div class="customNameBox">
                    <input
                      type="checkbox"
                      class="readOnly"
                      :checked="item.checked"
                    />
                    <span class="customNameFont">
                      Dirección {{ index + 1 }}
                    </span>
                  </div>
                  <div class="customConBox">
                    {{ item.address }},{{ item.city }} {{ item.zip_code }},
                    {{ item.country }}
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <!-- <div
            class="addressChioseOpt"
            @click="chiosePreset('2')"
            :class="{ active: address_type == '2', readOnly: !cantEdit }"
          >
            <div class="imgBox">
              <img
                style="width: 24px;height: 29px;"
                :src="require('@/assets/icon/imagologo.png')"
                alt=""
              />
            </div>
            <span>
              Recogida en Rakumart España
            </span>
          </div> -->
          <!-- 这个仓库发不了,所以隐藏 -->
          <div
            v-if="false"
            class="addressChioseOpt"
            @click="chiosePreset('3')"
            :class="{ active: address_type == '3', readOnly: !cantEdit }"
          >
            <div class="imgBox">
              <img
                style="width: 32px;height: 32px;"
                :src="require('@/assets/icon/capa.png')"
                alt=""
              />
            </div>
            <span>
              Beeping Fulfillment
            </span>
          </div>
          <div
            :class="{ active: address_type == '4', readOnly: !cantEdit }"
            class="addressChioseOpt"
            @click="chiosePreset('4')"
          >
            <div class="imgBox">
              <img
                style="width: 34px;height: 34px;"
                :src="require('@/assets/icon/Capa2048.png')"
                alt=""
              />
            </div>
            <span>
              Recogida para Amazon FBA
            </span>
          </div>
        </div>
        <!-- 右侧显示框 -->
        <div class="showChioseBox">
          <div
            class="showChioseBoxCon customAddress"
            v-if="address_type == '1'"
            key="io0"
          >
            <img :src="require('@/assets/icon/customAddress.png')" alt="" />
            <h2>
              Dirección
              {{
                userAddressList.findIndex((io) => io.id == userChiose.id) + 1 ||
                  ""
              }}
            </h2>
            <div class="conFont">
              {{ userChiose.address }},{{ userChiose.city }}
              {{ userChiose.zip_code }},
              {{ userChiose.country }}
            </div>
          </div>
          <div
            class="showChioseBoxCon chioseRakumart"
            v-else-if="address_type == '2'"
            key="io1"
          >
            <img
              style="width: 182px;height: 42px;"
              :src="require('@/assets/icon/ffsfas.png')"
              alt=""
            />
            <h2>Recogida en Rakumart España</h2>
            <div class="conFont">
              Indíquenos la dirección del almacén de Amazon que desea enviar a
              través de rakuchat o WhatsApp
            </div>
          </div>
          <div
            class="showChioseBoxCon chioseRakumart"
            v-else-if="address_type == '3'"
            key="io2"
          >
            <img
              style="width: 88px;height: 88px;"
              :src="require('@/assets/icon/BeepingFullfilment.png')"
              alt=""
            />
            <h2>Beeping Fulfillment</h2>
            <div class="conFont" style="width: 220px;">
              Carrer dels Artesans, 29, 46970 Alaquàs, Valencia España
            </div>
          </div>
          <div
            class="showChioseBoxCon chioseRakumart"
            v-else-if="address_type == '4'"
            key="io3"
          >
            <img
              style="width: 144px;height: 81px;"
              :src="require('@/assets/icon/amazon.png')"
              alt=""
            />
            <h2>Recogida para Amazon FBA</h2>
            <div class="conFont">
              Indíquenos la dirección del almacén de Amazon que desea enviar a
              través de rakuchat o WhatsApp
            </div>
          </div>
          <div
            class="showChioseBoxCon chioseAmazon"
            :class="{ readOnly: !cantEdit }"
            v-else
            key="io4"
            @click="openAddBox()"
          >
            <img
              style="width: 40px;height: 40px;"
              :src="require('@/assets/icon/addAddress.png')"
              alt=""
            />
            <div class="conFont">
              Selecciona o añade una nueva dirección
            </div>
          </div>
        </div>
      </div>
      <div class="rightChioseAnfPriceBox">
        <div class="priceBox">
          <h1>
            {{ $fanyi("所有产品的总价") }}:
            <span class="price">
              {{
                $fun.EURNumSegmentation(
                  $fun.ceil(
                    ($parent.orderPriceGroup.sum_price || 0) *
                      $parent.datas.exchange_rate
                  )
                )
              }}€
            </span>
          </h1>
          <div class="priceOrder">
            <h2>{{ $fanyi("产品成本细目") }}</h2>
            <p>
              {{ $fanyi("产品价值")
              }}<span class="otherPrice">
                {{
                  $fun.EURNumSegmentation(
                    $fun.ceil(
                      $parent.orderPriceGroup.goods_price *
                        $parent.datas.exchange_rate
                    )
                  )
                }}€({{
                  $fun.RMBNumSegmentation($parent.orderPriceGroup.goods_price)
                }}￥)</span
              >
            </p>
            <p>
              <span
                >{{ $fanyi("佣金") }}({{
                  Math.round(
                    Number($parent.orderPriceGroup.service_rate) * 100
                  )
                }}%)</span
              ><span class="otherPrice">
                {{
                  $fun.EURNumSegmentation(
                    $fun.ceil(
                      $parent.orderPriceGroup.service_rate_price *
                        $parent.datas.exchange_rate
                    )
                  )
                }}€({{
                  $fun.RMBNumSegmentation(
                    $parent.orderPriceGroup.service_rate_price
                  )
                }}￥)
              </span>
            </p>
            <p>
              {{ $fanyi("额外服务")
              }}<span class="otherPrice">
                {{
                  $fun.EURNumSegmentation(
                    $fun.ceil(
                      $parent.orderPriceGroup.option_price *
                        $parent.datas.exchange_rate
                    )
                  )
                }}€({{
                  $fun.RMBNumSegmentation($parent.orderPriceGroup.option_price)
                }}￥)
              </span>
            </p>
            <p>
              {{ $fanyi("其他费用")
              }}<span class="otherPrice">
                {{
                  $fun.EURNumSegmentation(
                    $fun.ceil(
                      $parent.orderPriceGroup.others_price *
                        $parent.datas.exchange_rate
                    )
                  )
                }}€({{
                  $fun.RMBNumSegmentation($parent.orderPriceGroup.others_price)
                }}￥)
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$fanyi('添加收货地址')"
      width="803px"
      :visible.sync="show"
      ref="odialog"
      destroy-on-close
    >
      <orderjinkoushangForm ref="DizhiForm" />
    </el-dialog>
  </div>
</template>
<script>
import orderjinkoushangForm from "./orderdizhiForm.vue";
export default {
  data() {
    return {
      address_type: null, //1-客户收件地址 2-rakumart仓库 3-deeping仓库 4-亚马逊仓库
      userChiose: {}, //用户选择的地址
      userAddressList: [], //用户收货人地址列表
      show: false,
      openelCollapse: "", //控制el-collapse
      equivalentSurchargeError: false, //增值税错误样式
      addressError: false, // 地址选择错误样式控制
    };
  },
  components: { orderjinkoushangForm },
  computed: {
    orderStatus() {
      return this.$parent.orderStatus;
    },
    cantEdit() {
      return ["temporary", "editing"].includes(this.$parent.orderStatus);
    },
  },
  created() {
    this.getUserAddress();
  },
  methods: {
    // 选择是否代收等值附件费
    chioseequivalentSurcharge(value) {
      this.$parent.doesCompanyPayTax = value;
      this.equivalentSurchargeError = false;
    },
    // 打开添加地址的弹窗
    openAddBox(data) {
      this.show = true;
      this.$nextTick(() => {
        if (data) {
          this.$refs.DizhiForm.open(data);
        }
      });
    },
    // 获取用户地址列表
    getUserAddress() {
      this.$api.useraddressList().then((res) => {
        if (res.code != 0) return;
        this.userAddressList = res.data.consignee;
        // 如果是新添加的用户地址,由于数据是从表单组件上传下来的所以没有id,需要在这里查找并赋予
        if (this.userChiose.address && !this.userChiose.id) {
          let finddata = res.data.consignee.find(
            (kll) => kll.address == this.userChiose.address
          );
          if (finddata) {
            this.userChiose.id = finddata.id;
          }
        }
        this.setHave();
      });
    },
    // 选择用户自定义地址
    chioseThis(item, index) {
      this.addressError = false;
      if (item.checked) {
        item.checked = false;
        this.userChiose = {};
        this.address_type = null;
        this.userChiose = {};
      } else {
        this.userAddressList.forEach((element) => {
          element.checked = false;
        });
        item.checked = true;
        this.userChiose = item.id;
        this.userChiose = item;
        this.address_type = 1;
        this.openelCollapse = "";
      }
      this.$forceUpdate();
    },
    // 选择预设地址
    chiosePreset(type) {
      this.addressError = false;
      this.address_type = type;
      this.userAddressList.forEach((element) => {
        element.checked = false;
      });
      this.userChiose = {
        type: type,
      };
    },
    // 在接口返回数据的时候设置页面数据
    setHave(odata) {
      if (odata) {
        // console.log(odata.userChiose);
        this.address_type = odata.address_type;
        this.userChiose = odata.userChiose;
      }
      if (
        this.userAddressList.length &&
        this.userChiose.id &&
        this.address_type == 1
      ) {
        let chioseAddressData = this.userAddressList.find((adIt) => {
          // console.log(adIt.id, this.userChiose.id);
          return adIt.id == this.userChiose.id;
        });
        if (chioseAddressData) {
          chioseAddressData.checked = true;
        }
        // console.log(chioseAddressData);
      }
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../../../../../css/mixin.scss";

.SpecialNoteVue {
  // align-items: flex-start;
  min-height: 373px;
  width: 1400px;
  margin: 0 auto;
  .modelTitleAndequivalentSurcharge {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 3px 15px 15px;
    min-height: 43px;
    .moTitle {
      font-size: 16px;
      line-height: 9px;
    }
    .chioseBox {
      .readOnly {
        pointer-events: none;
      }
      span {
        cursor: pointer;
        font-size: 16px;
        margin-right: 6px;
      }
      img {
        width: 17px;
        height: 17px;
        cursor: pointer;
        transform: translateY(3px);
        margin-right: 17px;
      }
      .el-button {
        width: 74px;
        border-radius: 6px;
        border: 2px solid #e8e8e8;
        height: 43px;
      }
      .el-button.active {
        background: #1c2899;
        border: 2px solid #1c2899;
        color: white;
      }
      .el-button.error {
        border: 2px solid #ffccd2;
        animation: doudong 0.7s;
      }
    }
  }
  .SpecialNoteVueCon {
    display: flex;
  }
  .addressSelection {
    width: 910px;
    display: flex;
    .addressChioseList {
      width: 436px;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      margin-right: 20px;
      .addressChioseOpt {
        width: 436px;
        height: 69px;
        background: #ffffff;
        border: 2px solid #dbd7d6;
        border-radius: 6px;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        padding: 0 25px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        .imgBox {
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .addressChioseOpt.active {
        border: 2px solid #4f6fff;
      }
      .customAddressListBox {
        padding: 8px 0 0 25px;
        margin-right: 34px;
        height: 238px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 9px;
          height: 1px;
        }

        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 5px;
          -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
          background: #535353;
        }

        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          background: #ececec;
          -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
          border-radius: 5px;
        }
        .customAddressOptBox {
          margin-right: 25px;
          height: 69px;
          border: 2px solid #dbd7d6;
          border-radius: 2px;
          padding: 14px 12px;
          margin-bottom: 8px;
          cursor: pointer;
          .customNameBox {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            input {
              border: 2px solid #4f6fff;
              margin-right: 9px;
            }
            input[type="checkbox"]:checked {
              background-color: #4f6fff;
            }
            .customNameFont {
              font-size: 14px;
              line-height: 1;
              color: #2f2f2f;
            }
          }
          .customConBox {
            line-height: 1;
            height: 13px;
            font-size: 12px;
            padding-left: 26px;
            color: #8a8a8a;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .activeOpt {
          border-color: #4f6fff;
        }
      }
      .el-collapse {
        margin-bottom: 18px;
        height: 69px;
        position: relative;
        z-index: 1;
      }
      /deep/.el-collapse-item {
        border: 2px solid #dbd7d6;
        border-radius: 6px;
        .el-collapse-item__header {
          height: 69px;
        }
        .el-collapse-item__arrow {
          // display: none;
          font-weight: bold;
          font-size: 19px;
          margin-right: 30px;
          transform: rotate(90deg);
          &.is-active {
            transform: rotate(-90deg);
          }
        }
        .addressChioseOpt {
          margin-bottom: 0;
          border: none;
          span {
            font-size: 16px;
          }
        }
      }
    }
    .optError {
      .addressChioseOpt,
      /deep/.el-collapse-item {
        animation: doudong 0.7s;
        border: 2px solid #ffccd2;
      }
    }
    .showChioseBox {
      width: 436px;
      background: #ffffff;
      border-radius: 6px;
      margin-right: 20px;
      .showChioseBoxCon {
        height: 100%;
        border: 2px solid #4f6fff;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          margin-bottom: 20px;
        }
        h2 {
          font-size: 16px;
          font-weight: 600;
          color: #2c2c2c;
          line-height: 1;
          margin-bottom: 15px;
        }
        .conFont {
          font-size: 16px;
          font-weight: 400;
          color: #7d7d7d;
          line-height: 21px;
          width: 240px;
          text-align: center;
        }
      }
      .customAddress {
        img {
          width: 26px;
          height: 31px;
        }
      }
      .chioseRakumart {
        img {
          width: 182px;
          height: 46px;
        }
        .conFont {
          width: 240px;
        }
      }
      .chioseAmazon {
        cursor: pointer;
        border: 2px dashed #dbd7d6;
        img {
          margin-bottom: 40px;
        }
        .conFont {
          line-height: 16px;
          line-height: 1.1;
          color: #2c2c2c;
          width: unset;
        }
      }
    }
  }
  .rightChioseAnfPriceBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    .priceBox {
      padding: 31px 35px;
      background-color: white;
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      h1 {
        font-size: 18px;
        font-weight: 600;
        color: #2f2f2f;
        line-height: 23px;
        height: 18px;
        margin-bottom: 23px;
        .price {
          color: #ffaf3e;
        }
      }
      .priceOrder {
        > * {
          padding: 15px 0;
        }
        h2 {
          font-size: 14px;
          line-height: 22.8px;
          font-weight: 500;
          padding: 0;
          margin-bottom: 10px;
        }
        p {
          font-weight: 400;
          display: flex;
          min-height: 50px;
          font-size: 14px;
          line-height: 14px;
          color: #868686;
          justify-content: space-between;
          align-items: center;
          border-bottom: solid 1px #e8e8e8;
          .otherPrice {
            color: #2f2f2f;
          }
        }
      }
    }
  }
}
// 这是弹出框的样式,需要写在外面
.optionHints {
  padding: 18px;
  .con {
    width: 270px;
    * {
      line-height: 22.84px;
      color: #2f2f2f;
    }
    h1 {
      margin-bottom: 25px;
      font-size: 18px;
      font-weight: 600;
    }
    p {
      text-align: left;
      font-size: 16px;
    }
  }
}

/deep/.el-dialog {
  border-radius: 4px;
}
/deep/.el-dialog__header {
  padding: 35px 35px 0;
  border-bottom: none !important;
  font-size: 18px;
  font-weight: 600;
  color: #2f2f2f;
  line-height: 1;
  .el-icon-close {
    display: none;
  }
}

/deep/.el-dialog__body {
  padding: 30px 35px !important;
}
/deep/.el-dialog__footer {
  display: none;
}
</style>
