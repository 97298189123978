<template>
  <div>
    <div class="modelMain" v-if="porder_freight">
      <!-- {{ porder_detail }} -->
      <div class="huoyunTable">
        <div class="head tbc">
          <span
            v-if="
              $fun.findNo(statusList, pageStatus) >=
                $fun.findNo(statusList, '已发货')
            "
            >{{ $fanyi("物流单号") }}</span
          >
          <span>{{ $fanyi("发送方式") }}</span>
          <span>{{ $fanyi("数量2") }}</span>
          <span>{{ $fanyi("重量（Kg）") }}</span>
          <span>{{ $fanyi("体积") }}(m³)</span>
        </div>
        <div class="body">
          <div class="opt tbc">
            <!-- @click="getlogisticsInformation(huoYunData.express_no)" -->
            <span
              class="blue"
              v-if="
                $fun.findNo(statusList, pageStatus) >=
                  $fun.findNo(statusList, '已发货')
              "
              >{{ huoYunData.express_no }}</span
            >
            <span>{{ huoYunData.logistics }}</span>
            <span>{{ porder_freight.length }}</span>
            <span>{{ all_weight }}</span>
            <span>{{ all_volume }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="logisticsInfoBox modelMain" v-if="showlogisticsData">
      <div class="logisticsInfoBoxT1">{{ $fanyi("国家后勤") }}</div>
      <!-- 图标列表 -->
      <div class="dip iconListBox">
        <div
          class="flex acenter"
          v-for="(item, index) in iconList"
          :key="index"
          :class="{ active: index + 1 <= logistics_status }"
        >
          <div class="iconItemBox">
            <div class="iconBox">
              <img
                :src="index + 1 <= logistics_status ? item.img2 : item.img"
              />
            </div>
            <span>
              {{ item.font }}
            </span>
          </div>
          <div class="line" v-if="index != iconList.length - 1"></div>
        </div>
      </div>
      <!-- 物流状态列表 -->
      <div class="scrollHeightBox">
        <div class="logisticsStatusList">
          <div
            class="statusItem "
            :class="{ active: logindex == 0 }"
            v-for="(logitem, logindex) in logisticsData"
            :key="logindex"
          >
            <p class="con ">
              {{ logitem.msg }}
            </p>
            <p class="otime">{{ logitem.datetime }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      iconList: [
        {
          img:
            "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202503/67ce90efc82ff.svg",
          img2:
            "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202503/67ce90f262ed1.svg",
          font: "Envío gestionado por Rakumart",
        },
        {
          img:
            "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202503/67ce90f4a01bc.svg",
          img2:
            "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202503/67ce90f6e5814.svg",
          font: "En tránsito",
        },
        {
          img:
            "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202503/67ce90f92e070.svg",
          img2:
            "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202503/67ce90fb7c879.svg",
          font: "Entrega dentro de la UE",
        },
        {
          img:
            "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202503/67ce90fdcb888.svg",
          img2:
            "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202503/67ce91000bbb7.svg",
          font: "Entregado",
        },
      ],

      showlogisticsData: false,
      logisticsData: [],
    };
  },
  components: {},
  computed: {
    all_weight() {
      let weight = 0;
      this.porder_freight.forEach((item) => {
        weight += item.weight;
      });
      return weight;
    },
    all_volume() {
      let volume = 0;
      this.porder_freight.forEach((item) => {
        volume += item.volume;
      });
      return volume;
    },
    logistics_status() {
      return (this.$parent.datas.result || {}).logistics_status || 1;
    },
    statusList() {
      return this.$parent.statusList;
    },
    pageStatus() {
      return this.$parent.pageStatus;
    },
    porder_freight() {
      if (
        this.$parent.datas.result &&
        this.$parent.datas.result.porder_freight
      ) {
        return this.$parent.datas.result.porder_freight;
      }
    },
    porder_foreign_express() {
      if (
        this.$parent.datas.result &&
        this.$parent.datas.result.porder_foreign_express
      ) {
        return this.$parent.datas.result.porder_foreign_express;
      }
    },
    huoYunData() {
      if (this.$parent.datas.result) {
        return {
          logistics: this.$parent.datas.result.logistics_name,
          express_no: this.$parent.datas.result.express_no,
          billing_parameter: this.$parent.datas.result.billing_parameter,
          // 2023/02/02 15:37 这里取国际物流费用
          amount: this.$fun.EURNumSegmentation(
            this.$parent.datas.result.logistics_price
          ),
        };
      }
    },
  },
  created() {},
  methods: {
    // 获取物流跟踪信息
    getlogisticsInformation(express_no) {
      this.$parent.fullscreenLoading = true;
      this.logisticsData = [];
      this.$api
        .getPorderExpressTrace({ express_no: express_no })
        .then((res) => {
          ////console.log('事件名',res)
          this.$parent.fullscreenLoading = false;
          if (res.code != 0 || res.data == [] || res.data == null) return;
          //接下来的操作
          this.logisticsData = (res.data || {}).trace;
          this.showlogisticsData = true;
          this.$forceUpdate();
        });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../../../../../../css/mixin.scss";

.modelMain {
  width: $pageWidth;
  margin: 20px auto;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e1e1e1;
  padding: 30px;

  .huoyunTable {
    .head {
      background: #f0f0f0;
      height: 56px;

      &.tbc {
        span {
          border: none !important;
        }
      }
    }

    .body {
      background-color: white;
      border: 1px solid #ededed;

      .opt {
        height: 60px;
      }
    }

    .tbc {
      display: flex;
      align-items: center;

      span {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        border-left: #ededed solid 1px;
        border-bottom: #ededed solid 1px;
      }
    }
  }

  .tipBox {
    line-height: 1;
    font-size: 13px;
    margin-top: 20px;
  }

  .logisticsInfoBox {
  }
  .logisticsInfoBoxT1 {
    line-height: 1;
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 18px;
  }

  .iconListBox {
    margin-bottom: 60px;
  }

  .iconListBox {
    .iconItemBox {
      width: 165px;

      .iconBox {
        width: 80px;
        height: 80px;
        background: #f0f0f0;
        border-radius: 20px;
        @extend .dip;
        margin: 0 auto 10px;
        img {
          width: 43px;
        }
      }

      span {
        display: block;
        text-align: center;
        line-height: 23px;
        font-size: 18px;
        font-weight: bold;
        min-height: 46px;
      }
    }
    .line {
      border-top: 2px dashed #f0f0f0;
      background-size: 40px;
      background-position: center;
      width: 128px;
      display: block;
      height: 0px;
      margin-bottom: 56px;
    }

    .active {
      .iconItemBox {
        .iconBox {
          background: #eef5fe;
        }
        span {
          color: #1a73e8;
        }
      }
      .line {
        border-color: #1a73e8;
      }
    }
  }

  .scrollHeightBox {
    height: 350px;
    overflow-y: auto;
    .logisticsStatusList {
      margin-left: 10px;

      .statusItem {
        position: relative;
        padding-left: 30px;
        padding-bottom: 40px;
        border-left: #e1e1e1 solid 2px;
        &:last-child {
          border-color: transparent;
          padding-bottom: 0px;
        }
        &::before {
          content: " ";
          width: 18px;
          height: 18px;
          background: #e1e1e1;
          border-radius: 50%;
          display: block;
          position: absolute;
          top: 0px;
          left: -10px;
        }

        .con {
          font-weight: 500;
          font-size: 18px;
          line-height: 23px;
          margin-bottom: 10px;
        }
        .otime {
          line-height: 1;
          font-size: 16px;
          color: #868686;
        }
      }
      .statusItem.active {
        &::before {
          background: #1a73e8;
        }
        .con {
          color: #1a73e8;
        }
      }
    }
  }
}

.blue {
  color: #1a73e8;
}
</style>
