<!-- 订单详情页面-单一产品成本预估下载模块 -->
<template>
  <div class="modelMain">
    <div class="jsb acenter">
      <div class="fontLeft">
        <h3 class="modelTitle">Los detalles del pedido</h3>
        <p class="blue">
          1. [Costo por articulo]: La hoja de calculo calcula detalladamente el
          costo de enviar cada articulo utilizando diferentes métodos de
          transporte hasta su destino final. (Rakumart es responsable del
          despacho de aduanas y la entrega a su dirección.)
        </p>
        <p class="yellow">
          2. [Más información]: Además, muestra el tamaño y el peso de cada
          artículo proporcionado por los proveedores, lo que facilita la
          comprensión de los productos en todo el pedido.
        </p>
        <p class="green">
          3. Puede hacer clic en el botón para descargar la hoja de cálculo.
        </p>
        <el-button class="bgBlueBtn" @click="goDownLoad">
          {{ $fanyi("单一产品成本估算") }}
        </el-button>
      </div>
      <div>
        <div class="imageRight">
          <img
            width="541px"
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202503/67cbd5769441b.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import urlHead from "@/api/wangZhi.js";
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {
    goDownLoad() {
      let url = `${urlHead}/client/download.costAnalysis?order_sn=${this.$route.query.order_sn}`;

      let a = document.createElement("a");
      a.target = "_blank";
      a.href = url;

      a.click();
      document.removeChild(a);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.modelMain {
  width: $pageWidth;
  height: 410px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e1e1e1;
  padding: 42px 30px 42px 60px;
  margin: 0 auto;
  color: #2f2f2f;
  .modelTitle {
    line-height: 1;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 8px;
  }
  .fontLeft {
    @extend .fdcolmun;
    gap: 22px;
    margin-right: 65px;
    p {
      line-height: 25px;
      font-size: 16px;
      min-height: 42px;
      @extend .acenter;
      background-color: transparent !important;
      &::before {
        margin-right: 20px;
        content: " ";
        width: 2px;
        flex: 0 0 2px;
        height: 42px;
      }
    }
    .blue {
      &::before {
        background-color: #1a71e8;
      }
    }
    .yellow {
      &::before {
        background-color: #ffa200;
      }
    }
    .green {
      &::before {
        background-color: #47bd9b;
      }
    }
    .bgBlueBtn {
      width: 500px;
      height: 60px;
      border-radius: 4px;
      border-width: 2px;
      margin-top: 5px;
    }
  }
}
</style>
