<template>
  <div>
    <div class="addUserInformation">
      <!-- 费用 -->
      <div class="priceBox modelBox">
        <h1>
          {{ $fanyi("国际运输费用") }}
        </h1>
        <div class="priceOrder">
          <p>
            {{ $fanyi("国际运输费用") }}:<font class="otherPrice">
              {{ $fun.EURNumSegmentation(datas.logistics_price) }} €</font
            >
          </p>
          <!-- 反倾销税 -->
          <p>
            {{ $fanyi("关税") }}:<font class="otherPrice">
              {{ $fun.EURNumSegmentation(datas.clearance_price) }} €</font
            >
          </p>
          <p>
            {{ $fanyi("增值税") }}:<font class="otherPrice">
              {{ $fun.EURNumSegmentation(datas.added_price) }} €</font
            >
          </p>
          <p>
            {{ $fanyi("西班牙物流费用") }}:<font class="otherPrice">
              {{ $fun.EURNumSegmentation(datas.foreign_express_price) }} €</font
            >
          </p>
          <!-- <p>
            R.E:<font class="otherPrice">
              {{ $fun.EURNumSegmentation(datas.personal_added_price) }} €</font
            >
          </p> -->
          <p>
            {{ $fanyi("其他费用") }}:<font class="otherPrice">
              {{ $fun.EURNumSegmentation(datas.other_price) }} €</font
            >
          </p>
          <p>
            {{ $fanyi("汇率") }}:<font class="otherPrice">
              1CNY ≈ {{ exchange_rate }}EUR</font
            >
          </p>
          <p>
            {{ $fanyi("总费用") }}:<font class="otherPrice yellow">
              {{
                $fun.EURNumSegmentation($fun.ceil($parent.orderData.zongJia))
              }}
              €</font
            >
          </p>
        </div>
        <el-button
          v-if="['等待付款'].includes(pageStatus)"
          class="bgGreenBtn_h"
          @click="goPay"
          >{{ $fanyi("支付账单") }}</el-button
        >
      </div>
      <!-- 进口商地址 -->
      <div class="setShippingAddress modelBox">
        <div class="modelTitle">{{ $fanyi("进口商信息") }}</div>
        <div class="addInfo">
          <div class="addInfocolumns">
            <!-- 第一行 -->
            <div class="addInfoRow">
              <div class="addInfoTd" style="flex:0 0 180px">
                <div class="addInfoTdTitle">
                  {{ $fanyi("全名") }}
                </div>
                <div class="addInfoTdBody">
                  <span>{{ userActiveAdd.importer.contacts }}</span>
                </div>
              </div>
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("邮编") }}</div>
                <div class="addInfoTdBody">
                  <span> {{ userActiveAdd.importer.zip_code }}</span>
                </div>
              </div>
            </div>
            <!-- 第二行 -->
            <div class="addInfoRow">
              <div class="addInfoTd">
                <div class="addInfoTdTitle">
                  {{ $fanyi("公司(可选)") }}
                </div>
                <div class="addInfoTdBody">
                  <span>{{ userActiveAdd.importer.company }}</span>
                </div>
              </div>
            </div>
            <!-- 第三行 -->
            <div class="addInfoRow">
              <div class="addInfoTd">
                <div class="addInfoTdTitle">
                  {{ $fanyi("地址") }}
                </div>
                <div class="addInfoTdBody textareaBox">
                  {{ userActiveAdd.importer.address }}
                </div>
              </div>
            </div>
            <!-- 第四行 -->
            <div class="addInfoRow">
              <div class="addInfoTd" style="flex:0 0 180px">
                <div class="addInfoTdTitle">
                  {{ $fanyi("电话") }}
                </div>
                <div class="addInfoTdBody">
                  <span>{{ userActiveAdd.importer.mobile }}</span>
                </div>
              </div>
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("邮件箱") }}</div>
                <div class="addInfoTdBody">
                  <span> {{ userActiveAdd.importer.email }}</span>
                </div>
              </div>
            </div>
            <!-- 第五行 -->
            <div class="addInfoRow">
              <div class="addInfoTd" style="flex:0 0 180px">
                <div class="addInfoTdTitle">
                  {{ $fanyi("省份2") }}
                </div>
                <div class="addInfoTdBody">
                  <span>{{ userActiveAdd.importer.country }}</span>
                </div>
              </div>
              <div class="addInfoTd">
                <div class="addInfoTdTitle">
                  {{ $fanyi("城市") }}
                </div>
                <div class="addInfoTdBody">
                  <span>{{ userActiveAdd.importer.city }}</span>
                </div>
              </div>
            </div>
            <!-- 第六行 -->
            <div class="addInfoRow">
              <div class="addInfoTd">
                <div class="addInfoTdTitle">
                  NIF/CIF
                </div>
                <div class="addInfoTdBody">
                  <span>{{ userActiveAdd.importer.cnpj }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 收件人地址 -->
      <div class="setShippingAddress modelBox">
        <div class="modelTitle">{{ $fanyi("信息接收者") }}</div>
        <div class="addInfo">
          <div class="addInfocolumns">
            <!-- 第一行 -->
            <div class="addInfoRow">
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("全名") }}</div>
                <div class="addInfoTdBody">
                  <span>{{ userActiveAdd.consignee.contacts }}</span>
                </div>
              </div>
            </div>
            <!-- 第二行 -->
            <div class="addInfoRow">
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("邮编") }}</div>
                <div class="addInfoTdBody">
                  <span> {{ userActiveAdd.consignee.zip_code }}</span>
                </div>
              </div>
            </div>
            <!-- 第三行 -->
            <div class="addInfoRow">
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("地址") }}</div>
                <div class="addInfoTdBody textareaBox">
                  {{ userActiveAdd.consignee.address }}
                </div>
              </div>
            </div>
            <!-- 第四行 -->
            <div class="addInfoRow">
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("电话") }}</div>
                <div class="addInfoTdBody">
                  <span>{{ userActiveAdd.consignee.mobile }}</span>
                </div>
              </div>
            </div>
            <!-- 第五行 -->
            <div class="addInfoRow">
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("邮件箱") }}</div>
                <div class="addInfoTdBody">
                  <span>{{ userActiveAdd.consignee.email }}</span>
                </div>
              </div>
            </div>
            <!-- 第六行 -->
            <div class="addInfoRow">
              <div class="addInfoTd" style="flex:0 0 180px">
                <div class="addInfoTdTitle">
                  {{ $fanyi("城市") }}
                </div>
                <div class="addInfoTdBody">
                  <span>{{ userActiveAdd.consignee.city }}</span>
                </div>
              </div>
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("国家") }}</div>
                <div class="addInfoTdBody">
                  <span> {{ userActiveAdd.consignee.country }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Dialog from "../../../../../../../components/public/Dialog.vue";
import Europess from "../../../../../../../api/wangZhi";
let Europe = Europess + "/client/";
import axios from "axios";
import { DOWLOAD_FILE } from "@/utlis/dowload";
export default {
  data() {
    return {
      fullscreenLoading: false,
      showAddressList: false,
      config: {
        top: "20vh",
        width: "1000px",
        title: this.$fanyi("收货地址详情"),
        btnTxt: [this.$fanyi("关闭")],
      },
    };
  },
  components: { Dialog },
  computed: {
    datas() {
      return this.$parent.datas.result;
    },
    gerenshui() {
      return this.$parent.gerenshui;
    },
    userActiveAdd() {
      return this.$parent.userActiveAdd;
    },
    userActiveAddName() {
      return this.$parent.userActiveAddName;
    },
    form() {
      return this.$parent.form;
    },
    pageStatus() {
      return this.$parent.pageStatus;
    },
    useraddLists() {
      return this.$parent.useraddLists;
    },
    exchange_rate() {
      return this.$parent.exchange_rate;
    },
    goPay() {
      return this.$parent.goPay;
    },
  },
  created() {},
  methods: {
    getFapiao() {
      this.fullscreenLoading = true;
      let params = {
        porder_sn: this.$parent.datas.porder_sn,
      };

      axios
        .post(Europe + "download.porderProformaInvoice", params, {
          responseType: "blob", // 1.首先设置responseType对象格式为 blob:
        })
        .then((res) => {
          this.fullscreenLoading = false;

          if (res.size < 200) {
            return this.$message.warning(this.$fanyi("操作失败"));
          }

          let blob = new Blob([res], {
            type: "application/vnd.ms-excel",
          });
          // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
          let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象

          // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
          let a = document.createElement("a");
          a.href = url;
          a.download = "INVOICE_" + this.$parent.datas.porder_sn + ".xlsx";
          a.click();
          // 5.释放这个临时的对象url
          window.URL.revokeObjectURL(url);
          this.diaShow = !this.diaShow;
        })
        .catch((error) => {
          this.$message(this.$fanyi(error.message));
        });
    },
    downloadFile(url, name = "imagen") {
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", name);
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.addUserInformation {
  display: flex;
  align-items: flex-start;
  width: 1400px;
  margin: 0 auto 20px;
  gap: 20px;

  .modelBox {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    padding: 30px;
    .modelTitle {
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 30px;
    }
    &.setShippingAddress {
      flex: 0 0 430px;
      .addInfo {
        display: flex;
        align-items: flex-start;
        .addInfocolumns {
          margin-right: 24px;
          &:last-child {
            margin-right: 0;
          }
          flex: 1;
          .addInfoRow {
            display: flex;
            margin-bottom: 25px;
            &:last-child {
              margin-bottom: 0;
            }
            gap: 10px;
            .addInfoTd {
              flex: 1;

              .addInfoTdTitle {
                font-size: 12px;
                font-weight: 400;
                line-height: 1;
                margin-bottom: 10px;
              }
              .addInfoTdBody {
                height: 42px;
                background: #ffffff;
                border: 1px solid #dde0e6;
                border-radius: 4px;
                padding: 0px 15px;

                font-size: 14px;
                line-height: 1;
                font-weight: 400;
                span {
                  @extend .text-overflow-one;
                  line-height: 42px;
                }
                &.textareaBox {
                  height: 64px;
                  overflow: auto;
                  padding: 10px 15px;
                }
              }
            }
          }
        }
      }
    }
    &.priceBox {
      flex: 1;
      padding: 30px;
      h1 {
        font-size: 18px;
        font-weight: bold;
        color: #000;
        line-height: 1;
        margin-bottom: 20px;
      }
      .priceOrder {
        > * {
          padding: 15px 0;
        }

        p {
          font-weight: 400;
          display: flex;
          font-size: 14px;
          line-height: 14px;
          color: #868686;
          justify-content: space-between;
          align-items: center;
          border-bottom: solid 1px #e8e8e8;
          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
          .otherPrice {
            color: #2f2f2f;
            font-weight: bold;
          }
          .otherPrice.yellow {
            font-weight: bold;
            font-size: 14px;
            color: #ffa724;
          }
        }
      }
      .bgGreenBtn_h {
        margin-top: 30px;
        width: 100%;
        height: 60px;
        font-size: 16px;
      }
    }
  }
}
</style>
