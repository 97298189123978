<template>
  <div
    element-loading-text=""
    element-loading-background="rgba(0, 0, 0, 0.3)"
    class="orderPage"
    v-loading.fullscreen.lock="fullscreenLoading"
  >
    <HomePageTop ref="tyop"></HomePageTop>
    <div v-if="showOrder">
      <div v-if="!placingSuccessfully">
        <DialogVue ref="dialogRef" />
        <whyDiaVue ref="whyDia"></whyDiaVue>
        <whyDia2Vue ref="whyDia2"></whyDia2Vue>

        <!-- 替换商品 -->
        <replaceProductModel
          v-if="replaceGoodsArr.length"
          :replaceGoodsArr="replaceGoodsArr"
          :canEditQuestionNumber="canEditQuestionNumber"
          @pageLoad="pageLoad"
          @fullscreenLoading="
            (v) => {
              fullscreenLoading = v;
            }
          "
        />
        <!-- 表格 -->
        <!-- 除了临时保存,修改中,报价中状态都显示这个表格 -->
        <div
          class="main zhu base-w dingDanXiangQing"
          :class="{ AllGoods: showAllGoods }"
          v-if="['temporary', 'editing', 'offer'].indexOf(orderStatus) == -1"
        >
          <!-- 头部 -->
          <div class="head">
            <div>
              <div class="headT1">{{ headText }}</div>
              <div class="orderSnBox">
                {{ $fanyi("订单号") }} ：{{ datas.order_sn }}
              </div>
            </div>
            <div class="dip">
              <span> {{ $fanyi("时间") }} : {{ datas.created_at }} </span>
              <button
                v-if="isedit"
                class="editOrder bgBlueBtn"
                type="primary"
                @click="
                  oldobligation = orderStatus;
                  orderStatus = 'editing';
                  changeOrder();
                "
              >
                <img :src="require('@/assets/icon/edit-02.svg')" alt="" />
                <span>{{ $fanyi("修改要求") }}</span>
              </button>
            </div>
          </div>
          <changeOAndP
            v-model="showOrder"
            style="margin-bottom: 20px"
            v-if="porder_sn_set.length"
          />

          <offerList
            v-if="['temporary', 'editing', 'offer'].indexOf(orderStatus) == -1"
          />
        </div>

        <!-- 临时保存,修改中,报价中等状态 -->
        <templateList v-else />
        <!-- 不能操作的问题产品 -->
        <errorList
          :errorListData="errorListData"
          :orderStatus="orderStatus"
          :datas="datas"
          v-if="orderStatus == 'obligation'"
        />
        <!-- 单一产品成本预估下载 -->
        <downloadInvoice v-if="datas.status >= 30" />
        <!-- 送货地址选择和是否需要交re选择 -->
        <SpecialNoteVue
          v-show="
            ['temporary', 'editing', 'offer', 'delete'].indexOf(orderStatus) !=
              -1
          "
          ref="specialNoteRef"
        />

        <ModeOofTransportation
          ref="odeOofTrans"
          v-if="
            ['obligation', 'purchased', 'purchase'].indexOf(orderStatus) != -1
          "
        />
        <!-- 购物车弹窗 -->
        <el-dialog
          title=""
          class="cartDia"
          :visible.sync="showCart"
          width="1500px"
        >
          <addGoods v-if="showCart"></addGoods>
        </el-dialog>

        <Coupons ref="couponsPage" />
        <customerPrompt
          ref="customerPrompt"
          style="background: transparent; box-shadow: none; height: 0px"
        ></customerPrompt>
        <!-- 用户操作选项 -->
        <div class="operatingOptions">
          <div class="choies">
            <input
              type="checkbox"
              name=""
              id=""
              v-model="checked"
              @change="allChoice"
            />
            <button
              class="qx"
              @click="
                checked = !checked;
                allChoice();
              "
            >
              {{ $fanyi("全选") }}
            </button>
            <el-popover placement="top-start" width="258" trigger="click">
              <p class="caoZuoAnNiu">
                <button
                  class="addgoods"
                  v-if="['temporary', 'editing'].indexOf(orderStatus) != -1"
                  @click="addGoodsbtn()"
                >
                  {{ $fanyi("从购物车中导入更多产品") }}
                </button>
              </p>
              <p class="caoZuoAnNiu">
                <button
                  class="delBtn"
                  v-if="['temporary', 'editing'].indexOf(orderStatus) != -1"
                  @click="delOrder"
                >
                  {{ $fanyi("删除") }}
                </button>
              </p>
              <p class="caoZuoAnNiu">
                <button class="addLibraryOfGoods" @click="addLibraryOfGoods">
                  {{ $fanyi("加入商品库") }}
                </button>
              </p>

              <span
                class="caoZuoCaiDan"
                v-show="['temporary', 'editing'].indexOf(orderStatus) != -1"
                slot="reference"
              >
                {{ $fanyi("操作菜单") }}</span
              >
            </el-popover>

            <button
              v-if="['temporary', 'editing'].indexOf(orderStatus) == -1"
              class="addLibraryOfGoods"
              @click="addLibraryOfGoods"
            >
              <div class="addIcon">+</div>
              {{ $fanyi("加入商品库") }}
            </button>
          </div>
          <div class="shop">
            <p class="shopGr">
              <span
                >{{ $fanyi("店铺") }}： <b>{{ numberOfStores.stores }}</b>
              </span>
              <span
                >{{ $fanyi("商品种类") }}：<b>{{ numberOfStores.goods }}</b>
                <span
                  class="tiJi"
                  v-if="
                    ['obligation', 'purchased', 'purchase'].indexOf(
                      orderStatus
                    ) != -1
                  "
                >
                </span>
              </span>
              <span>
                {{ $fanyi("数量总计") }}：<b>{{ quantityOfCommodity }}</b>
              </span>
              <span
                v-if="
                  [
                    'obligation',
                    'offer',
                    'purchase',
                    'purchased',
                    'delete',
                  ].indexOf(orderStatus) != -1
                "
                >{{ $fanyi("总体积") }}：&nbsp;<b
                  >{{ $fun.ceil(datas.orderVolume_m3) }}&nbsp;m³</b
                >
              </span>
            </p>
            <!-- 区分待支付和其他状态的操作栏价格区域显示 -->
            <p class="prices" v-if="orderStatus != 'obligation'">
              <span class="totalAmount">
                {{ $fanyi("总价") }}:
                <font
                  class="priceBox"
                  :class="{
                    price: ['temporary', 'editing'].indexOf(orderStatus) != -1,
                  }"
                  >{{
                    $fun.EURNumSegmentation(
                      $fun.ceil(orderPriceGroup.sum_price * datas.exchange_rate)
                    )
                  }}€</font
                ></span
              >
              <span class="priceEURBox">
                <font class="huiLv">(1RMB≈{{ datas.exchange_rate }}EUR)</font>
                <font class="priceBox">
                  {{ $fun.RMBNumSegmentation(orderPriceGroup.sum_price) }}
                  <font class="renMingBi">RMB</font>
                </font>
              </span>
              <span
                class="youHuiQuan"
                v-if="['purchased', 'purchase'].indexOf(orderStatus) != -1"
              >
                <el-popover placement="bottom" trigger="click">
                  <el-table
                    :data="datas.order_money_group.coupon_list"
                    :cell-style="{ 'text-align': 'center' }"
                  >
                    <el-table-column
                      width="300"
                      property="title"
                      :label="$fanyi('优惠券')"
                    ></el-table-column>
                  </el-table>
                  <button
                    class="couponDetail"
                    v-show="datas.order_money_group.coupon_list.length"
                    slot="reference"
                  >
                    {{ $fanyi("明细") }}&nbsp;
                  </button>
                </el-popover>
                {{ $fanyi("优惠金额") }} ：<font class="priceBox"
                  >{{
                    $fun.EURNumSegmentation(
                      $fun.ceil(
                        orderPriceGroup.discount_amount * datas.exchange_rate
                      )
                    )
                  }}€</font
                >
              </span>

              <span
                class="shiYongYouHuiQuan"
                @click="CouponsShow = !CouponsShow"
                v-if="
                  ['obligation'].indexOf(orderStatus) != -1 && haveYouHuiQuan
                "
              >
                <span class="tiShi" v-if="youHuiQuanShiYong"
                  >（ {{ $fanyi("优惠金额") }}:
                  {{
                    $fun.EURNumSegmentation(
                      (
                        $fun.ceil(
                          orderPriceGroup.sum_price * datas.exchange_rate
                        ) -
                        $fun.ceil(
                          orderPriceGroup.sum_discount_price *
                            datas.exchange_rate
                        )
                      ).toFixed(2)
                    )
                  }}€）</span
                >
                <el-checkbox
                  style="pointer-events: none"
                  v-model="youHuiQuanShiYong"
                ></el-checkbox>
                <button class="userYouHuiQuan">
                  <span v-if="!youHuiQuanShiYong">{{ $fanyi("执行券") }}</span>
                  <p v-if="youhuiSmall">{{ $fanyi("已使用优惠券") }}</p>
                  <p v-if="daijinSmall">{{ $fanyi("已使用代金券") }}</p>
                </button>
              </span>
              <!-- <span
            class="shiYongYouHuiQuan"
            v-if="['obligation'].indexOf(orderStatus) != -1 && !haveYouHuiQuan"
          >
            <span class="tiShi">{{ $fanyi("暂无优惠券") }}</span>
          </span> -->
              <span
                v-if="
                  ['obligation', 'purchased', 'purchase'].indexOf(
                    orderStatus
                  ) != -1
                "
              >
                {{ $fanyi("实际支付金额") }}: &nbsp;
                <font class="price priceBox"
                  >{{
                    $fun.EURNumSegmentation(
                      $fun.ceil(
                        orderPriceGroup.sum_discount_price * datas.exchange_rate
                      )
                    )
                  }}€</font
                >
              </span>
            </p>
            <p class="amountPaid" v-else>
              <!-- 优惠券模块 -->
              <!-- 2023/2/27 优惠券模块改成没有也显示出来 haveYouHuiQuan-->
              <span
                class="shiYongYouHuiQuan"
                v-if="['obligation'].indexOf(orderStatus) != -1"
              >
                <!-- :style="{ 'pointer-events': !youHuiQuanShiYong ? 'none' : '' }" -->
                <el-switch
                  v-model="youHuiQuanShiYong"
                  @click.native="
                    youHuiQuanShiYong
                      ? (CouponsShow = !CouponsShow)
                      : cancelCoupons()
                  "
                  active-color="#1C2899"
                  inactive-color="#E8E8E8"
                >
                </el-switch>
                <button
                  class="userYouHuiQuan"
                  @click="CouponsShow = !CouponsShow"
                >
                  <span v-if="!youHuiQuanShiYong">{{ $fanyi("执行券") }}</span>
                  <span class="tiShi" v-if="youHuiQuanShiYong">
                    {{ $fanyi("优惠金额") }}:
                    {{
                      $fun.EURNumSegmentation(
                        (
                          $fun.ceil(
                            orderPriceGroup.sum_price * datas.exchange_rate
                          ) -
                          $fun.ceil(
                            orderPriceGroup.sum_discount_price *
                              datas.exchange_rate
                          )
                        ).toFixed(2)
                      )
                    }}€</span
                  >
                </button>
              </span>
              <!-- 价格模块 -->
              <span class="amountPaidPrice">
                <span>
                  <span class="amountPaidPriceTit"
                    >{{ $fanyi("产品支出总额") }}:</span
                  >
                  <!-- 2022/11/17 总价应该是sum_discount_price,不是sum_price -->
                  <span class="amountPaidPricCon">
                    <span class="ouYuan">
                      {{
                        $fun.EURNumSegmentation(
                          orderPriceGroup.sum_discount_price_eu
                        )
                      }}€
                    </span>
                    <span class="renMingBi">
                      {{
                        $fun.RMBNumSegmentation(
                          $fun.ceil(orderPriceGroup.sum_discount_price)
                        )
                      }}￥
                    </span>
                  </span>
                </span>
              </span>
            </p>
          </div>
          <div
            class="buttonGroup"
            v-if="['temporary'].indexOf(orderStatus) != -1"
          >
            <div class="btnDiv" @click="OrdersSubmitted(10)">
              <img :src="require('@/assets/icon/wenjianjia.png')" alt="" />
              <span>{{ $fanyi("保存") }}</span>
            </div>

            <button @click="OrdersSubmitted(20)">
              {{ $fanyi("上市申请") }}
            </button>
          </div>
          <div
            class="buttonGroup"
            v-if="['editing'].indexOf(orderStatus) != -1"
          >
            <button @click="pageLoad">{{ $fanyi("取消") }}</button>
            <button class="chongXinTiChu" @click="resubmit">
              {{ $fanyi("重新提出") }}
            </button>
          </div>
          <div class="buttonGroup" v-else-if="orderStatus == 'obligation'">
            <button
              class="payBtn"
              @click="payMent"
              :class="{ disabledul: canEditQuestionNumber }"
            >
              {{ $fanyi("付款") }}
            </button>
          </div>
        </div>

        <FootVue />
      </div>
      <placingSuccessfullyPage
        v-else
        ref="querenyemian"
      ></placingSuccessfullyPage>
    </div>
    <div v-else class="porderBox">
      <!-- 头部 -->
      <div class="whiteCard">
        <div class="head">
          <div>
            <div class="headT1">{{ headText }}</div>
            <div class="orderSnBox">
              {{ $fanyi("订单号") }} ：{{ datas.order_sn }}
            </div>
          </div>
          <div class="dip">
            <span> {{ $fanyi("时间") }} : {{ datas.created_at }} </span>
          </div>
        </div>
        <changeOAndP v-model="showOrder" />
      </div>

      <modelWuliu :modeluse_porder_sn="porder_sn_set" />
    </div>
  </div>
</template>
<script>
import changeOAndP from "./components/changeOAndP.vue";
import modelWuliu from "@/views/UserCenter/views/deliveryList/views/DeliveryDetails/index.vue";
import replaceProductModel from "./newCom/replaceProductModel.vue";
import templateList from "./newCom/templateList.vue";
import addGoods from "./components/addGoods.vue";
import { getCurrentDate } from "../../../../../utlis/date";
import HomePageTop from "../../../../../components/head/HomePageTop.vue";
import { mapState } from "vuex";
import SpecialNoteVue from "./newCom/SpecialNote.vue";
import ModeOofTransportation from "./newCom/ModeOofTransportation.vue";
import Coupons from "./components/Coupons.vue";
import customerPrompt from "./components/customerPrompt";
import placingSuccessfullyPage from "./newCom/placingSuccessfullyPage";
import SustituciónDeBienes from "./newCom/SustituciónDeBienes.vue";
import whyDiaVue from "./newCom/whyDia.vue";
import whyDia2Vue from "./newCom/whyDia2.vue";
import FootVue from "../../../../../components/foot/Foot.vue";
import DialogVue from "@/components/public/Dialog.vue";
import errorList from "./newCom/errorList.vue";
import offerList from "./newCom/offerList.vue";
import router from "../../../../../router";
import downloadInvoice from "./components/downloadInvoice.vue";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      porder_sn_set: [], //配送单列表
      showOrder: true,
      fullscreenLoading: true,
      oldobligation: "",
      logistics: "",
      placingSuccessfully: false, //是否显示成功提出订单页面
      orderStatus: "", //订单状态名(临时保存的：temporary，报价中：offer，等待付款：obligation，购买中：purchase，购买完成：purchased，删除：delete,修改中 : editing)
      userActiveAdd: {
        importer: {},
        consignee: {},
      },
      userActiveAddName: {
        importer: "",
        consignee: "",
      },
      sorting: 1, //番号
      pay_logistics_fees: 0, //订单支付的时候是否连同运费一起支付0-不一起支付,1-一起支付
      activeIds: [], //选中的id
      ids: [], //获取列表时的所有id
      newList: [], // 列表数据
      checked: false, //   全选状态
      showCart: false, // 购物车弹窗状态
      optionList: [], // 附加服务列表
      oneGoodsOptionTotalPrice: 0, //一条商品的附加服务总价
      showOptionList: false, //附加服务列表弹窗状态
      checkItem: {}, //正在操作的列表
      datas: {},
      orderPriceGroup: {}, //价格数据
      showPriceDetail: false, //附加服务明细
      doesCompanyPayTax: false, //是否公司交税
      //运输方式下拉框控制
      transportationControl: {
        show: false,
        active: 0,
        options: [
          { name: this.$fanyi("海上运输"), value: 0 },
          // { name: "航空运输", value: 1 },
          // { name: "ocs", value: 2 },
        ],
      },
      youHuiQuanShiYong: false, //使用优惠券或代金券判断
      daijinSmall: false, //使用优惠券判断
      youhuiSmall: false, //使用优惠券判断
      CouponsShow: false, //使用优惠券弹窗
      CouponsData: {
        ids: "",
        str: "",
      }, // 优惠券使用数据
      isedit: false,
      quantityOfCommodity: 0, //商品数量
      client_remark: "", //订单特殊要求备注
      haveYouHuiQuan: false, //是否拥有优惠券
      wuLiuOptions: [],
      showAllGoods: this.$route.query.type == "temporary" ? true : false, //商品表格是否显示全部
      replaceProductNum: 0, //所有商品的可替换商品数量
      replaceGoodsArr: [], //待替换商品数组
      errorListData: [],
    };
  },

  components: {
    addGoods,
    HomePageTop,
    SpecialNoteVue,
    ModeOofTransportation,
    Coupons,
    customerPrompt,
    placingSuccessfullyPage,
    SustituciónDeBienes,
    whyDiaVue,
    whyDia2Vue,
    templateList,
    FootVue,
    DialogVue,
    replaceProductModel,
    errorList,
    offerList,
    downloadInvoice,
    modelWuliu,
    changeOAndP,
  },

  computed: {
    ...mapState(["rate", "excelOrder"]),
    headText() {
      if (this.orderStatus == "obligation") {
        return this.$fanyi("付款详情");
      } else {
        return this.$fanyi("订单内容");
      }
    },
    getCurrentDate() {
      return getCurrentDate(2);
    },
    shouqi() {
      let scrollTop = this.$store.state.topScrollMoreHeight;
      if (scrollTop >= 190) {
        return true;
      } else if (scrollTop <= 100) {
        return false;
      }
      return true;
    },

    orderOptons() {
      let allOptionPrice = 0;
      let activeIds = [];
      for (let a in this.newList) {
        for (let b in this.newList[a].list) {
          for (let c in this.newList[a].list[b].option) {
            allOptionPrice +=
              this.newList[a].list[b].option[c].price *
              this.newList[a].list[b].option[c].num;
          }
        }
      }
      this.activeIds = activeIds;
      return allOptionPrice;
    },

    // 计算店铺数量、产品数量和附加服务明细
    numberOfStores() {
      let stores = 0;
      let goods = 0;
      let useOption = [];
      for (let i in this.newList) {
        stores++;
        for (let j in this.newList[i].list) {
          for (let e in this.newList[i].list[j].option) {
            useOption.push({
              name: this.newList[i].list[j].option[e].name,
              num: this.newList[i].list[j].option[e].num,
              // total: (
              //   this.newList[i].list[j].option[e].price *
              //   this.newList[i].list[j].option[e].num
              // ).toFixed(2),
            });
          }
          goods++;
        }
      }
      let optionList = JSON.parse(JSON.stringify(this.optionList));
      optionList.forEach((item) => {
        item.num = 0;
        useOption.forEach((btem) => {
          if (item.name == btem.name) {
            item.num = Number(item.num) + Number(btem.num);
          }
        });
        item.total = this.$fun.ceil(item.num * item.price);
      });
      // 不显示为0的附加服务
      for (let i = 0; i < optionList.length; i++) {
        if (optionList[i].num == 0) {
          optionList.splice(i, 1);
          i--;
        }
      }
      return {
        stores: stores,
        goods: goods,
        useOption: optionList,
      };
    },

    // 可以操作替换的问题商品条数
    canEditQuestionNumber() {
      let num = 0;
      this.replaceGoodsArr.forEach((element) => {
        if (element.itemStatus == "questionReStatus") {
          num++;
        }
      });
      return num;
    },
  },

  created() {
    this.pageLoad();
    this.getwuLiu();
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },

  methods: {
    handleTabClick() {
      this.showOrder = !this.showOrder;
    },
    // 确认选择该商品事件
    confirmeElReemplazo(goodsId) {
      this.fullscreenLoading = true;
      this.$api
        .replaceGoods({
          replace_id: goodsId,
        })
        .then((res) => {
          if (res.code != 0) {
            this.fullscreenLoading = true;
            return this.$message.error(res.meg);
          }
          this.pageLoad();
        });
      return;
      this.$refs.dialogRef.open(
        this.$fanyi("是否确认删除？"),
        () => {},
        () => {}
      );
    },

    // 根据用户选择的物流方式返回西班牙费用
    xiBanYaYunFei(item) {
      // 单个商品的西班牙费用数据
      let arr = item.client_buy_amount;
      let actId = 0;
      if (
        this.$refs.odeOofTrans &&
        this.datas.orderOfferLogistics &&
        this.datas.orderOfferLogistics[this.$refs.odeOofTrans.userActOpt]
      ) {
        // 运输方式选择组件里的index
        let optid = this.datas.orderOfferLogistics[
          this.$refs.odeOofTrans.userActOpt || 0 //打开页面时this.$refs.odeOofTrans.userActOpt的值是underfind，默认取第一个物流方式的id
        ].id;
        actId = arr.findIndex((item) => {
          return item.id == optid;
        });
      }
      this.datas.orderOfferLogistics[0]; //删了导致页面刚打开时商品金额数据不能取到对应物流的数据，只能取到第一个数据
      return actId;
    },
    // 获取物流方式列表
    getwuLiu() {
      this.$api.internationalLogisticsTransportation().then((res) => {
        if (res.code != 0) return;
        if (res.data.length == 0) return false;
        this.wuLiuOptions = res.data;
        this.logistics = res.data[0].name;
      });
    },
    // 修改订单操作
    changeOrder() {},
    // 商品详情选择，传入参数：已选中的选项，选择的选项,单条商品数据
    goodsDetailChoice(detail, checkedDetail, oneGoods) {
      // console.log(detail, checkedDetail, oneGoods);
      if (checkedDetail.picUrl) {
        oneGoods.pic = checkedDetail.picUrl;
      }
      detail.value = checkedDetail.name;
      let str = [];
      oneGoods.detail.forEach((item) => {
        str.push(item.value);
      });
      this.getPrice(str, oneGoods);

      if (oneGoods.detail.length != oneGoods.pageSpecificationChinese.length) {
        this.reset_goods_detail(oneGoods);
      } else {
        this.set_goods_chinese_detail();
      }
    },
    // 根据选择条件匹配价格
    getPrice(str, oneGoods) {
      oneGoods.totalPrice = Number(
        this.$fun.ceil(oneGoods.num * oneGoods.price)
      );
    },
    // 重新拼凑商品的详情
    reset_goods_detail(oneGoods) {
      let detailEnglish = [];
      let detailChinese = [];
      oneGoods.pageSpecification.forEach((element, eleindex) => {
        let pageSpecificationChineseItem =
          oneGoods.pageSpecificationChinese[eleindex];
        detailEnglish.push({
          key: element.key,
          value: element.value[element.active].name,
        });
        detailChinese.push({
          key: pageSpecificationChineseItem.key,
          value: pageSpecificationChineseItem.value[element.active].name,
        });
      });
      oneGoods.detail = detailEnglish;
      oneGoods.detailChinese = detailChinese;
    },
    // 为英文已选规格匹配中文已选规格
    set_goods_chinese_detail(checkchceked) {
      let errorGoods = [];
      for (let i = 0; i < this.newList.length; i++) {
        let newListItem = this.newList[i];
        for (let j = 0; j < newListItem.list.length; j++) {
          let newListItemListItem = newListItem.list[j];
          let detailChinese = [];
          let detailEnglish = newListItemListItem.detail; //英文已选规格
          let pageSpecification = newListItemListItem.pageSpecification; //英文所有规格列表
          let pageSpecificationChinese =
            newListItemListItem.pageSpecificationChinese; //中文所有规格列表
          if (detailEnglish.length != pageSpecificationChinese.length) {
            if (
              (checkchceked && newListItemListItem.checked) ||
              !checkchceked
            ) {
              errorGoods.push("NO" + newListItemListItem.sorting);
            }
          } else {
            detailEnglish.forEach((item, index) => {
              detailChinese.push({
                key: pageSpecificationChinese[index].key,
                value:
                  pageSpecificationChinese[index].value[
                    pageSpecification[index].active
                  ].name,
              });
            });
            newListItemListItem.detailChinese = detailChinese;
          }
        }
      }
      if (errorGoods.length != 0) {
        //有chcek代表只有选中的商品需要返回提示并停止操作
        if (this.$store.state.fanyi) {
          this.$message.error(
            `“ ${errorGoods.join(",")}”规格已过期，无法购买。选择其他产品规格。`
          );
          return false;
        } else {
          this.$message.error(
            `La especificación del ” ${errorGoods.join(
              ","
            )}“ ha caducado y no se puede comprar. Seleccione otra especificación del producto.`
          );
          return false;
        }
      }
    },
    // 加入商品库
    addLibraryOfGoods() {
      // 循环查找已选中的商品
      let chechkedGoods = [];
      let setFlog = this.set_goods_chinese_detail(true);
      if (setFlog == false) {
        return false;
      }
      for (let i = 0; i < this.newList.length; i++) {
        let newListItem = this.newList[i];
        for (let j = 0; j < newListItem.list.length; j++) {
          let newListItemListItem = newListItem.list[j];
          if (newListItemListItem.checked) {
            chechkedGoods.push({
              shop_type: 1688,
              goods_id: newListItemListItem.goods_id,
              goods_title: newListItemListItem.goods_title,
              shop_id: newListItem.shop_id,
              shop_name: newListItem.shop_name,
              shop_name_translate: newListItem.shop_name_translate,
              img_url: newListItemListItem.pic,
              price_range: newListItemListItem.price_range, //价格幅度
              detailChinese: newListItemListItem.detailChinese,
              //规格核对
              specification_checked: ((
                pageSpecification,
                pageSpecificationChinese
              ) => {
                let specification_checked = [];
                pageSpecification.forEach(
                  (pageSpecificationItem, pageSpecificationIndex) => {
                    specification_checked.push({
                      key: pageSpecificationChinese[pageSpecificationIndex].key,
                      value:
                        pageSpecificationChinese[pageSpecificationIndex].value[
                          pageSpecificationItem.active
                        ].name,
                    });
                  }
                );

                return specification_checked;
              })(
                newListItemListItem.pageSpecification,
                newListItemListItem.pageSpecificationChinese
              ),

              // specification_checked: JSON.stringify(newListItemListItem.detail),
              option: JSON.stringify(this.optionList),
            });
          }
        }
      }
      if (chechkedGoods.length == 0)
        return this.$message(this.$fanyi("请至少选择一个商品"));
      // return;
      this.$api
        .favoriteInventoryBatchSave({
          data: JSON.stringify(chechkedGoods),
        })
        .then((res) => {
          if (res.code != 0) return;
          this.$message.success(this.$fanyi("加入商品库成功"));
        });
    },
    // 计算单条商品订单的附加服务费用(只计算选中的附加服务)
    setOneGoodsOptionTotalPrice() {
      let sum = 0;
      this.optionList.forEach((tt) => {
        if (tt.checked == true) {
          sum += tt.num * tt.price;
        }
      });
      this.oneGoodsOptionTotalPrice = sum;
      this.$forceUpdate();
    },
    // 追加商品
    addGoodsbtn() {
      if (this.numberOfStores.goods >= 100) {
        return this.$message.warning(this.$fanyi("一个订单最多添加100个商品"));
      }
      this.showCart = !this.showCart;
    },
    // 保存选择的物流信息
    saveSelectedLogisticsInfo() {
      this.$api
        .updateOrderLogistics({
          order_sn: this.$route.query.order_sn,
          logistics:
            (
              (
                this.datas.orderOfferLogistics[
                  this.$refs.odeOofTrans.userActOpt
                ] || {}
              ).logistics || {}
            ).id || this.wuLiuOptions[0].id,
          pay_logistics_fees: this.pay_logistics_fees,
        })
        .then((res) => {
          this.calculateOrder();
        });
    },
    // 跳转到付款页面
    payMent() {
      if (
        this.$refs.odeOofTrans.userActOpt == -1 &&
        this.pay_logistics_fees == 1
      ) {
        return this.$message.warning(this.$fanyi("请至少选择一项物流方式"));
      }

      // 修改物流方式成功之后跳转，否则弹出错误信息
      this.$api
        .updateOrderLogistics({
          order_sn: this.$route.query.order_sn,
          logistics:
            (
              (
                this.datas.orderOfferLogistics[
                  this.$refs.odeOofTrans.userActOpt
                ] || {}
              ).logistics || {}
            ).id || this.logistics,
          pay_logistics_fees: this.pay_logistics_fees,
        })
        .then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          let urlStr = "?order_sn=" + this.$route.query.order_sn;
          if (this.CouponsData.ids) {
            urlStr += "&coupon_ids=" + this.CouponsData.ids;
          }
          this.$fun.routerToPage("/payment" + urlStr);
          setTimeout(() => {
            this.$bus.emit("parameterName", this.newList);
          }, 100);
        });
    },
    cancelCoupons() {
      this.$refs.couponsPage.cancelCoupons();
    },
    // 重新提出
    resubmit() {
      if (this.doesCompanyPayTax == null) {
        this.$refs.specialNoteRef.equivalentSurchargeError = false;
        setTimeout(() => {
          this.$refs.specialNoteRef.equivalentSurchargeError = true;
        }, 300);
        return;
      }
      if (this.$refs.specialNoteRef.address_type == null) {
        this.$refs.specialNoteRef.addressError = false;
        setTimeout(() => {
          this.$refs.specialNoteRef.addressError = true;
        }, 300);
        return;
      }
      let setFlog = this.set_goods_chinese_detail();
      if (setFlog == false) {
        return false;
      }
      // return console.log(this.datas.detail, this.newList);
      let data = JSON.parse(JSON.stringify(this.newList));
      data.forEach((listItem) => {
        listItem.list.forEach((Item) => {
          // 2023/6/30 删除商品中的以下数据,因为修改订单不知道出什么问题了,叫小何把这些用不到的整理出来不提交了,还能省点带宽
          delete Item.specification;
          delete Item.specificationChinese;
          delete Item.goods_inventory;
          delete Item.goods_inventoryChinese;
          delete Item.pageSpecification;
          delete Item.pageSpecificationChinese;
          delete Item.price_range;
        });
      });
      this.datas.detail = data;
      this.datas.address_type = this.$refs.specialNoteRef.address_type;
      this.datas.invoice_type = this.doesCompanyPayTax ? 2 : 1;
      this.datas.consignee_address = JSON.stringify(
        this.$refs.specialNoteRef.userChiose
      );
      // 如果客户没有选自定义地址就删除consignee_address
      if (this.$refs.specialNoteRef.address_type != 1) {
        delete this.datas.consignee_address;
      }
      // return console.log(this.datas);
      this.$api.orderEdit({ data: JSON.stringify(this.datas) }).then((res) => {
        if (res.code == 0) {
          this.$fun.routerToPage("/user/order?status=all");
        }
      });
    },
    pageLoad() {
      // 判断是购物车还是订单列表跳转的页面
      if (this.$route.query.id) {
        this.orderStatus = "temporary";
        this.newOrder();
      } else if (this.$route.query.order_sn && this.$route.query.type) {
        this.getData(this.$route.query.order_sn);
      }
      // 获取订单状态
      // this.orderStatus = this.$route.query.type;
      // 附加服务列表
      this.getOptionList();
    },
    // 通过订单号获取数据
    getData(order_sn) {
      this.fullscreenLoading = true;
      this.$api
        .orderDetail({
          order_sn: order_sn,
          vvv: "v2",
        })
        .then((res) => {
          this.fullscreenLoading = false;
          if (res.code != 0) {
            return this.$message.error(res.msg);
          }

          if (
            res.data.status == 30 &&
            res.data.order_money_group.sum_discount_price == 0
          ) {
            this.$message.error(this.$fanyi("当订单金额为0时,无法付款"));
          }
          if (res.data.invoice_type && res.data.invoice_type == 2) {
            this.doesCompanyPayTax = true;
          } else {
            this.doesCompanyPayTax = false;
          }

          // 如果状态是待报价和接口返回的edit是true就跳到修改订单页面
          if (this.$route.query.type == "offer" && res.data.edit == true) {
            this.orderStatus = "editing";
          }

          // 数据赋值
          this.isedit = res.data.edit;
          this.datas = res.data;
          this.porder_sn_set = res.data.porder_sn_set || [];
          if (res.data.logistics) {
            this.logistics = res.data.logistics;
          }
          if (res.data.pay_logistics_fees) {
            this.pay_logistics_fees = res.data.pay_logistics_fees;
          }
          this.ordno = res.data.ordno;
          this.tenshu = res.data.tenshu;
          this.newList = res.data.detail;
          this.orderPriceGroup = res.data.order_money_group;
          this.client_remark = res.data.client_remark;
          this.orderStatus = res.data.order_status;
          // 如果订单状态为已付款待财务确认，就显示已付款页面
          if (this.orderStatus == "Pending financial confirmation") {
            this.orderStatus = "purchased";
          }
          // 2023/5/19 等待付款页面默认选择支付运费,所以如果状态不是等待支付才获取用户选择的支付方式
          if (this.orderStatus != "obligation") {
            this.pay_logistics_fees = res.data.pay_logistics_fees || 0;
          }

          // 根据状态修改tag标签
          if (this.orderStatus == "temporary") {
            if (this.$route.query.id) {
              this.$store.commit(
                "changeTagTitle",
                "Rakumart - Guardar pedido temporalmente"
              );
            } else {
              this.$store.commit(
                "changeTagTitle",
                "Rakumart - Pedidos en espera de cotización"
              );
            }
          } else if (this.orderStatus == "offer") {
            this.$store.commit(
              "changeTagTitle",
              "Rakumart - Pedidos en espera de cotización"
            );
          } else if (this.orderStatus == "obligation") {
            this.$store.commit(
              "changeTagTitle",
              "Rakumart - Pedidos pendientes de pago"
            );
          } else if (this.orderStatus == "purchase") {
            this.$store.commit(
              "changeTagTitle",
              "Rakumart - Pedidos de compra pendientes"
            );
          } else if (this.orderStatus == "purchased") {
            this.$store.commit(
              "changeTagTitle",
              "Rakumart - Pedido de compra completada"
            );
          } else if (["cancel", "delete"].includes(this.orderStatus)) {
            this.$store.commit("changeTagTitle", "Rakumart - Pedido cancelado");
          } else {
            this.$store.commit(
              "changeTagTitle",
              "Rakumart - Detalles del pedido"
            );
          }

          // 调用弹出相应提示函数
          // this.$refs.customerPrompt.openOrderDia();
          // if (this.orderStatus == "temporary") {
          //   this.$refs.whyDia.open();
          // }
          res.data.orderOfferLogistics.forEach((logisticsIt) => {
            logisticsIt.sumPrice = this.$fun.ceil(
              Number(logisticsIt.added_price) +
                Number(logisticsIt.clearance_price) +
                Number(logisticsIt.foreign_express_price) +
                Number(logisticsIt.international_freight) +
                Number(logisticsIt.personal_added_price)
            );
          });
          this.$nextTick(() => {
            this.$refs.specialNoteRef.setHave({
              address_type: res.data.address_type,
              userChiose: res.data.orderAddress.consignee_address || {},
            });
          });

          // 调用处理数据的函数
          this.dataProcessing();
          this.getYouHuiQuan();
        });
    },

    /*********************************************************************************************** */
    // 点击页面时关闭所有弹窗
    bodyCloseMenus(e) {
      this.transportationControl.show = false;
      if (e.target.className != "detail fujiafuwu") {
        this.showPriceDetail = false;
      }
      if (
        ["additionalServices", "inputOption"].indexOf(e.target.className) == -1
      ) {
        this.newList.forEach((item) => {
          item.list.forEach((jtem) => {
            jtem.accessorialService = false;
            this.$forceUpdate();
          });
        });
      }
      if (
        ["detailWindowBody", "goodsDetailOpt"].indexOf(e.target.className) == -1
      ) {
        this.newList.forEach((item) => {
          item.list.forEach((jtem) => {
            jtem.detailWindow = false;
            this.$forceUpdate();
          });
        });
      }
    },
    // 追加商品,传入参数：添加的商品数据
    addTo(addGoodsData) {
      // 如果是待支付状态的订单，就把追加商品的价格转换成报价价格字段
      if (this.oldobligation == "obligation") {
        addGoodsData.newList.forEach((shopitem, shopindex) => {
          shopitem.list.forEach((goodsitem, goodsindex) => {
            // 从商品库添加的商品如果数量为0就把他改为1
            if (goodsitem.num == 0) {
              goodsitem.num = 1;
            }
            goodsitem.order_sn = this.$route.query.order_sn;
            goodsitem.confirm_price = goodsitem.price;
            goodsitem.confirm_num = goodsitem.num;
          });
        });
      }
      this.addToDataProcessing(addGoodsData);
    },
    // 追加商品数据处理
    addToDataProcessing(addData) {
      // 将追加的商品数据放入当前列表
      addData.newList.forEach((item) => {
        // 判断是否是同一店铺，是的话将商品加入同一店铺下,因为不同种类id不同所以不用判断种类
        let isSame = false;
        this.newList.forEach((jtem) => {
          if (item.shop_name == jtem.shop_name) {
            item.list.forEach((etem) => {
              jtem.list.push(etem);
              isSame = true;
            });
          }
        });
        if (isSame == false) {
          this.newList.push(item);
        }
      });
      // 将所有商品重新添加序号
      let sort = 1;
      this.newList.forEach((jtem) => {
        jtem.list.forEach((etem) => {
          etem.sorting = sort;
          sort++;
        });
      });
      this.calculateOrder();
      this.dataProcessing();
    },

    // 删除商品
    delOrder() {
      // 双层循环删除商品和店铺并删除路由上的query的id以传值给添加购物车去除重复商品使用
      for (let a = 0; a < this.newList.length; a++) {
        for (let b = 0; b < this.newList[a].list.length; b++) {
          // 如果商品数组的checked是true就删除商品和$route.query.id里的cart_id
          if (this.newList[a].list[b].checked == true) {
            this.newList[a].list.splice(b, 1);
            b--;
          }
        }
        // 如果店铺的list数组没有数据了就删除店铺
        if (this.newList[a].list.length == 0) {
          this.newList.splice(this.newList.indexOf(this.newList[a]), 1);
          a--;
        }
      }
      this.dataProcessing();
      // 提交后端计算价格
      this.calculateOrder();
    },
    // 提交或临时保存
    OrdersSubmitted(type) {
      if (this.newList.length == 0) {
        return this.$message(this.$fanyi("请至少添加一个商品"));
      } else if (this.newList.length > 100) {
        return this.$message(this.$fanyi("一个订单最多添加100个商品"));
      }
      // 是否提交附加税必选限制
      if (this.doesCompanyPayTax == null) {
        this.$refs.specialNoteRef.equivalentSurchargeError = false;
        setTimeout(() => {
          this.$message.error(
            this.$fanyi("你必须选择一个关于等价附加费的选项")
          );
          this.$refs.specialNoteRef.equivalentSurchargeError = true;
        }, 300);
        return;
      }
      // 收货地址必选限制
      if (this.$refs.specialNoteRef.address_type == null) {
        this.$refs.specialNoteRef.addressError = false;
        setTimeout(() => {
          this.$refs.specialNoteRef.addressError = true;
        }, 300);
        return;
      }
      // return;
      let dataList = JSON.parse(JSON.stringify(this.datas));
      let setFlog = this.set_goods_chinese_detail();
      if (setFlog == false) {
        return false;
      }
      this.newList.forEach((listItem) => {
        listItem.list.forEach((Item) => {
          if (Item.option == "") {
            Item.option = [];
          }
          if (typeof Item.price_range == "object") {
            Item.price_range = JSON.stringify(Item.price_range);
          }
          if (typeof Item.goods_inventory == "object") {
            Item.goods_inventory = JSON.stringify(Item.goods_inventory);
          }
          if (typeof Item.specification == "object") {
            Item.specification = JSON.stringify(Item.specification);
          }
        });
      });
      dataList.detail = this.newList;
      dataList.logistics = this.logistics;
      let datas = {
        status: type,
        data: JSON.stringify(dataList),
        client_remark: dataList.client_remark,
        invoice_type: this.doesCompanyPayTax ? 2 : 1, //开票类型:1-公司,2-个人 如果用户选中传2,没选中传1
        address_type: this.$refs.specialNoteRef.address_type, //用户选择的地址类别 1-客户收件地址 2-rakumart仓库 3-deeping仓库 4-亚马逊仓库
      };
      // 如果客户选择自己的地址(address_type==1)就传这个
      // console.log(this.$refs.specialNoteRef.address_type);
      if (this.$refs.specialNoteRef.address_type == 1) {
        if (this.$refs.specialNoteRef.userChiose.user_id) {
          this.$refs.specialNoteRef.userChiose.user_Id = this.$refs.specialNoteRef.userChiose.user_id;
        }
        delete this.$refs.specialNoteRef.userChiose.acquiescent;
        datas.consignee_address = JSON.stringify(
          this.$refs.specialNoteRef.userChiose
        );
      }
      // console.log(datas);
      // return;

      this.$api.orderCreate(datas).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        window.scrollTo(0, 0);
        // 假如点击的是临时保存按钮
        if (type == 10) {
          this.$fun.routerToPage("/user/order?status=temporary");
        }
        // 假如点击的是提出按钮
        if (type == 20) {
          // this.$fun.routerToPage("/user/order?status=offer");
          this.placingSuccessfully = true;
          this.$refs.querenyemian.getBuyGoodsArr();
          // this.placingSuccessfully = true;
          this.$store.dispatch("sidebarNumList");
        }
      });
    },
    // 打开商品详细弹窗
    openDetailWindow(item) {
      this.newList.forEach((item) => {
        item.list.forEach((jtem) => {
          jtem.detailWindow = false;
          this.$forceUpdate();
        });
      });
      item.detailWindow = !item.detailWindow;
      this.$forceUpdate();
    },
    // 计算订单金额
    calculateOrder() {
      this.quantityOfCommodity = 0;
      let newList = JSON.parse(JSON.stringify(this.newList));
      let datas = [];
      for (let i in newList) {
        for (let j in newList[i].list) {
          if (newList[i].list[j].option == "") {
            newList[i].list[j].option = [];
          } else {
            newList[i].list[j].option = JSON.stringify(
              newList[i].list[j].option
            );
          }

          this.quantityOfCommodity += newList[i].list[j].num;
          datas.push(newList[i].list[j]);
        }
      }
      datas = JSON.stringify(datas);
      this.fullscreenLoading = true;
      this.$api
        .calculateOrder({ data: datas, coupon_ids: this.CouponsData.ids })
        .then((res) => {
          this.fullscreenLoading = false;
          if (res.code != 0) {
            this.youHuiQuanShiYong = false;
            this.CouponsData.ids = "";
            return this.$message.error(this.$fanyi(res.msg));
          }

          this.orderPriceGroup = res.data;
        });
    },
    // 获取附加服务列表
    getOptionList() {
      this.$api.optionList().then((res) => {
        this.optionList = res.data;
      });
    },
    // 设置附加服务-重置
    optionReact(item) {
      for (var i in this.optionList) {
        this.optionList[i].checked = false;
        this.optionList[i].num = 0;
        delete this.optionList[i].fba;
      }
      let checkOptionBox = document.getElementsByClassName("checkOptionBox");
      for (let i = 0; i < checkOptionBox.length; i++) {
        checkOptionBox[i].checked = false;
      }
    },
    // 选中附加服务时将商品数量填进附加服务数量
    setOptionsNum(optionItem, goodsNum) {
      if (optionItem.checked) {
        if (optionItem.name == "摄影") {
          optionItem.num = 1;
        } else {
          optionItem.num = goodsNum;
        }
      } else {
        optionItem.num = 0;
      }
    },

    // 设置附加服务-打开弹窗并初始化数据和选中已选附加服务
    setOrderOption(item) {
      // 初始化数据
      item.accessorialService = !item.accessorialService;
      this.checkItem = item;
      for (let i in this.optionList) {
        this.optionList[i].checked = false;
        this.optionList[i].num = 0;
        delete this.optionList[i].fba;
      }
      // 将列表中已有的东西赋值到打开的列表里
      for (let i in item.option) {
        for (let j in this.optionList) {
          if (item.option[i].name == this.optionList[j].name) {
            this.optionList[j].checked = item.option[i].checked;
            this.optionList[j].num = item.option[i].num;
            this.optionList[j].fba = item.option[i].fba;
          }
        }
      }
      // 将有提示的选项放到最前面
      this.optionList.sort((a, b) => {
        return b.id - a.id;
      });
      // 调用计算单个附加服务总价函数
      this.setOneGoodsOptionTotalPrice();
      this.$forceUpdate();
      // this.showOptionList = true;
    },
    // 订单预览
    newOrder(ids) {
      if (ids) {
        var datas = {
          cat_id: ids,
        };
      } else {
        if (this.$route.query.iscomm) {
          var datas = {
            inventory_id: this.$route.query.id,
          };
        } else {
          var datas = {
            cat_id: this.$route.query.id,
          };
        }
      }
      if (this.$route.query.iscomm) {
        this.ids = datas.inventory_id.split(",");
      } else {
        this.ids = datas.cat_id.split(",");
      }

      this.$api.orderPreview(datas).then((res) => {
        this.fullscreenLoading = false;
        this.datas = res.data;
        this.ordno = res.data.ordno;
        this.tenshu = res.data.tenshu;
        this.newList = res.data.detail;
        if (res.data.logistics) {
          this.logistics = res.data.logistics;
        }

        this.orderPriceGroup = res.data.order_money_group;
        // 调用弹出相应提示函数
        // this.$refs.customerPrompt.openOrderDia();
        // this.$refs.whyDia.open();
        // this.value = "9";
        this.dataProcessing();
      });
    },

    // 判断是否显示到第三个表格('errorList')里
    isThisTableGoods(goods) {
      // 是问题产品但是没有替换链接
      if (goods.itemStatus == "noNumquestionReStatus") {
        return true;
      }

      // 客户选择不替换
      if (goods.itemStatus == "irreplaceableStatus") {
        return true;
      }

      return false;
    },

    // 数据处理
    dataProcessing() {
      this.sorting = 1;
      let quantityOfCommodity = 0;
      let replaceProductNum = 0;
      for (let i in this.newList) {
        for (let j in this.newList[i].list) {
          if (this.newList[i].list[j].num == 0) {
            this.newList[i].list[j].num = 1;
          }
          if (this.orderStatus == "obligation") {
            // 判断商品状态
            let itemStatus = "normalStatus";
            // 改成了业务员后台选择标签之后显示为问题产品和有替换链接的产品
            if (
              // !this.newList[i].list[j].this_order_detail_is_confirm_num ||
              // this.newList[i].list[j].confirm_num <
              //   this.newList[i].list[j].num ||
              // this.newList[i].list[j].order_detail_replace.length != 0

              !![100, "100", 200, "200", 300, "300"].includes(
                this.newList[i].list[j].tag_status
              )
            ) {
              if (
                !this.newList[i].list[j].order_detail_replace ||
                !this.newList[i].list[j].order_detail_replace.length
              ) {
                // 没有替换链接的问题商品
                itemStatus = "noNumquestionReStatus";
              } //  replace_status==1代表用户选择不替换商品
              else if (this.newList[i].list[j].replace_status) {
                // 用户选择不替换的商品
                itemStatus = "irreplaceableStatus";
              } else {
                // 可以操作的问题商品
                itemStatus = "questionReStatus";
              }

              // 处理替换商品规格数据
              this.newList[i].list[j].order_detail_replace.forEach(
                (order_detail_replaceItem) => {
                  console.log(order_detail_replaceItem);
                  try {
                    order_detail_replaceItem.detail = JSON.parse(
                      order_detail_replaceItem.detail
                    );
                  } catch (error) {
                    order_detail_replaceItem.detail =
                      order_detail_replaceItem.detail;
                  }
                }
              );
            }
            // this_order_detail_is_client_default: true-是下单时商品 false代表是已经被替换的商品
            if (!this.newList[i].list[j].this_order_detail_is_client_default) {
              // 用户已经替换过的商品
              itemStatus = "reSuccessStatus";
            }

            this.newList[i].list[j].itemStatus = itemStatus;
          }

          this.newList[i].list[j].showOption = false;
          // 如果接口没有返回商品番号,就自己排一个番号给商品
          if (this.orderStatus == "temporary") {
            this.newList[i].list[j].sorting = this.sorting;
          }
          this.sorting++;

          // 总价计算
          if (
            this.newList[i].list[j].confirm_num &&
            this.newList[i].list[j].confirm_num > 0
          ) {
            this.newList[i].list[j].totalPrice = Number(
              this.$fun.ceil(
                this.newList[i].list[j].confirm_price *
                  this.newList[i].list[j].confirm_num
              )
            );
          } else {
            this.newList[i].list[j].totalPrice = Number(
              this.$fun.ceil(
                this.newList[i].list[j].price * this.newList[i].list[j].num
              )
            );
          }
          // this.newList[i].list[j].totalPrice = (
          //   this.newList[i].list[j].num * this.newList[i].list[j].price
          // ).toFixed(2);
          // 拿一份页面使用时使用的商品所有规格数据
          if (!!this.newList[i].list[j].specification) {
            if (typeof this.newList[i].list[j].specification != "object") {
              this.newList[i].list[j].pageSpecification = JSON.parse(
                this.newList[i].list[j].specification
              );
              this.newList[i].list[j].pageSpecificationChinese = JSON.parse(
                this.newList[i].list[j].specificationChinese
              );
            } else {
              this.newList[i].list[j].pageSpecification = this.newList[i].list[
                j
              ].specification;
              this.newList[i].list[j].pageSpecificationChinese = this.newList[
                i
              ].list[j].specificationChinese;
            }
          }

          let skuProps = [];
          Object.keys(this.newList[i].list[j].pageSpecification).forEach(
            (key) => {
              // 英文属性
              let enshuXing = this.newList[i].list[j].specification[key];
              // 选中商品下标
              let active = 0;
              // 选中的属性
              let xuanZhongShuXing = this.newList[i].list[j].detail;
              xuanZhongShuXing.forEach((xuanZhongitem, xuanZhongindex) => {
                enshuXing.forEach((enshuXingItem, enshuXingIndex) => {
                  if (enshuXingItem.name == xuanZhongitem.value) {
                    active = enshuXingIndex;
                  }
                });
              });
              skuProps.push({
                key: key.substr(1, key.length - 2),
                value: this.newList[i].list[j].pageSpecification[key],
                active: active,
              });
            }
          );
          this.newList[i].list[j].pageSpecification = skuProps;

          let skuPropsChinese = [];
          Object.keys(this.newList[i].list[j].pageSpecificationChinese).forEach(
            (key) => {
              let skuPropsChineseItem = {
                key: key.substr(1, key.length - 2),
                value: this.newList[i].list[j].pageSpecificationChinese[key],
              };

              skuPropsChinese.push(skuPropsChineseItem);
            }
          );
          // 将pageSpecification里每一项的active复制到pageSpecificationChinese上;
          this.newList[i].list[j].pageSpecification.forEach((enitem) => {
            skuPropsChinese.forEach((cnitem) => {
              cnitem.active = enitem.active;
            });
          });
          this.newList[i].list[j].pageSpecificationChinese = skuPropsChinese;
          this.newList[i].list[j].detailWindow = false;
          this.newList[i].list[j].accessorialService = false;
          this.newList[i].list[j].checked = false;
          quantityOfCommodity += this.newList[i].list[j].num;

          if (
            this.newList[i].list[j].order_detail_replace &&
            this.newList[i].list[j].this_order_detail_is_client_default
          ) {
            replaceProductNum += this.newList[i].list[j].order_detail_replace
              .length;
          }
          // 假如返回的附加服务列表是字符串，就转化成空数组
          if (this.newList[i].list[j].option == '"[]"') {
            this.newList[i].list[j].option = [];
          }

          // 如果没有option，就添加option
          if (!this.newList[i].list[j].option) {
            this.newList[i].list[j].option = [];
          }
          // 把接口返回的附加服务列表字符串转换为数组
          if (
            !!this.newList[i].list[j].option &&
            typeof this.newList[i].list[j].option != "object"
          ) {
            this.newList[i].list[j].option = JSON.parse(
              this.newList[i].list[j].option
            );
          }
          // 附加服务 接口返回的是所有选项, 删除其中未选中选项
          let lisopt = this.newList[i].list[j].option;
          for (let u = 0; u < lisopt.length; u++) {
            if (!this.newList[i].list[j].option[u].checked) {
              lisopt.splice(u, 1);
              u--;
            }
          }
        }
      }

      // 把商品里有异常的放到上面
      this.replaceGoodsArr = [];
      this.errorListData = [];
      for (let i in this.newList) {
        let nStatusArr = [];
        let canShowGoods = [];
        for (let j in this.newList[i].list) {
          if (
            ["questionReStatus", "reSuccessStatus"].includes(
              this.newList[i].list[j].itemStatus
            )
          ) {
            this.replaceGoodsArr.push(this.newList[i].list[j]);
          } else {
            nStatusArr.push(this.newList[i].list[j]);
          }

          if (!this.isThisTableGoods(this.newList[i].list[j])) {
            canShowGoods.push(this.newList[i].list[j]);
          } else {
            this.errorListData.push(this.newList[i].list[j]);
          }
        }

        // 不显示异常的商品
        this.newList[i].list = [...canShowGoods];
        // 如果店铺下的商品全部都不可替换或者客户选择不替换,就不显示这个店铺
      }

      // 删除去除问题产品后没有商品了的店铺
      for (let i = 0; i < this.newList.length; i++) {
        const shopItem = this.newList[i];
        if (!shopItem.list.length) {
          this.newList.splice(i, 1);
          i--;
        }
      }

      this.quantityOfCommodity = quantityOfCommodity;
      this.replaceProductNum = replaceProductNum;
      // this.calculateOrder();
    },

    getTotalPrice(item) {
      // 如果有人工报价价格和人工报价数量，就使用人工报价和人工报价数量计算总价
      if (item.confirm_num && item.confirm_num > 0) {
        // 计算总价时防止出现小数位过多情况限制最多显示三位小数
        item.totalPrice = this.$fun.ceil(item.confirm_price * item.confirm_num);
      } else {
        item.totalPrice = this.$fun.ceil(Number(item.price) * Number(item.num));
      }
      this.calculateOrder();
    },
    // 单选
    radio(i) {
      let flag = this.newList[i].list.every((item) => item.checked == true);
      if (flag) {
        this.newList[i].checked = true;
      } else {
        this.newList[i].checked = false;
      }
      this.isCheckedAll();
      this.newList.splice(0, 0); //更新errorList组件里的视图渲染
      this.$forceUpdate();
    },
    // 店铺全选
    shopAll(i) {
      if (this.newList[i].checked) {
        this.newList[i].list.forEach((item) => {
          item.checked = true;
        });
      } else {
        this.newList[i].list.forEach((item) => {
          item.checked = false;
        });
      }
      this.$forceUpdate();
      this.newList.splice(0, 0);
      this.isCheckedAll();
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.newList.forEach((item, index) => {
          item.checked = true;
          this.shopAll(index);
        });
      } else {
        this.newList.forEach((item, index) => {
          item.checked = false;
          this.shopAll(index);
        });
      }
      this.newList.splice(0, 0);
      this.$forceUpdate();
    },

    // 是否全选中
    isCheckedAll() {
      var flag = this.newList.every((item) => item.checked == true);
      if (flag == true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      this.$forceUpdate();
    },
    // 获取优惠券列表
    getYouHuiQuan() {
      this.$api
        .couponGet({ page: 1, pageSize: 999 })
        .then((res) => {
          if (res.data.length != 0) {
            this.haveYouHuiQuan = false;
            res.data.data.forEach((item, index) => {
              if (
                this.$fun.ceil(
                  this.orderPriceGroup.sum_price * this.datas.exchange_rate
                ) > item.restrictions
              ) {
                this.haveYouHuiQuan = true;
              }
            });
          } else {
            this.haveYouHuiQuan = false;
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss">
.el-popover.el-popper.dditionalServicesPro {
  background-color: transparent;
  box-shadow: none;
  border: none;

  .popper__arrow {
    display: none;
  }
}

button {
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
@import "@/css/mixin.scss";
@import "./css/orderdetailCss";
</style>
<style lang="scss">
// 单番商品预估费用明细
.breakdownOfExpenses {
  margin: 10px !important;

  .popper__arrow {
    // display: none !important;
  }
}

.orderDetailPopover {
  border-radius: 16px;
  border: 2px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  padding: 24px;
  font-size: 16px;
  line-height: 24px;
  .PoheadBox {
    display: flex;
    margin-bottom: 8px;
    img {
      width: 24px;
      margin-right: 16px;
    }
    span {
      font-weight: 700;
    }
  }
  .PobodyBox {
    text-align: left;
  }
  .popper__arrow {
    display: none;
  }
}
</style>
