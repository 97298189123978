<!-- 配送单模块-箱号信息和商品信息模块 -->
<template>
  <div
    class="modelMain"
    v-if="Array.isArray(porder_freight) && porder_freight.length"
  >
    <div class="modelTitle">
      <h2>{{ $fanyi("包装清单详细信息") }}</h2>
      <div class=" showAllBtn" @click="changeShowAllGoods" v-if="showButton">
        <span>{{ $fanyi("部署") }}</span>
        <img
          class="jiahaoIcon"
          v-if="!showAllGoods"
          :src="require('@/assets/icon/jiahao_fill.svg')"
        />
        <img
          class="jiahaoIcon"
          v-else
          :src="require('@/assets/icon/jianhao.svg')"
        />
      </div>
    </div>

    <div
      class="oneGoodsBox"
      v-for="(porder_freight_item, porder_freight_index) in porder_freight"
      :key="porder_freight_index"
    >
      <!-- 箱号信息 -->
      <div class="boxNumBox">
        Número de caja: &nbsp;
        <span class="numCor">{{ porder_freight_item.number }}</span>
      </div>

      <!-- 商品信息 -->
      <table class="listTableHtmlBox">
        <tr>
          <th>{{ $fanyi("订单号") }}</th>
          <th>NO</th>
          <th>{{ $fanyi("照片") }}</th>
          <th>{{ $fanyi("商品链接") }}</th>
          <th>
            Nombre del <br />
            producto en español
          </th>
          <th>{{ $fanyi("产品属性") }}</th>
          <th>{{ $fanyi("每包数量") }}</th>
          <th>{{ $fanyi("单价") }} <br />(€)</th>
          <th>{{ $fanyi("小计") }} <br />(€)</th>
        </tr>

        <tr
          v-for="(goods_item,
          goods_index) in porder_freight_item.porder_freight_detail"
          :key="goods_index"
          v-show="goods_index + 1 <= 2 || porder_freight_item.showAllGoods"
        >
          <!-- 订单号 -->
          <td>
            <div class="infoBox" style="width: 160px">
              <span
                class="aLink"
                @click="
                  $fun.routerToPage(
                    '/OrderDetails?type=' +
                      'peisong' +
                      '&order_sn=' +
                      (goods_item.order_detail || {}).order_sn,
                    true
                  )
                "
              >
                {{ (goods_item.order_detail || {}).order_sn }}
              </span>
            </div>
          </td>
          <!-- NO -->
          <td>
            <div class="infoBox" style="width: 50px">{{ goods_index + 1 }}</div>
          </td>
          <!-- 照片 -->
          <td>
            <div class="infoBox">
              <el-popover placement="right" trigger="hover">
                <img
                  :src="(goods_item.order_detail || {}).pic"
                  alt=""
                  style="width: 300px; height: 300px"
                />

                <el-image
                  slot="reference"
                  @click="
                    $fun.toCommodityDetails(
                      (goods_item.order_detail || {}).goods_id
                    )
                  "
                  :src="(goods_item.order_detail || {}).pic"
                >
                  <div
                    slot="error"
                    :src="(goods_item.order_detail || {}).pic"
                    class="image-slot"
                  >
                    <i class="el-icon-picture-outline"></i>
                  </div>
                  <div slot="placeholder">
                    <i class="el-icon-loading"></i>
                  </div>
                </el-image>
              </el-popover>
            </div>
          </td>
          <!-- 商品链接 -->
          <td>
            <div class="infoBox" style="width: 200px">
              <span
                class="aLink"
                @click="
                  $fun.toCommodityDetails(
                    (goods_item.order_detail || {}).goods_id
                  )
                "
              >
                {{ host }}/ProductDetails?goods_id={{
                  (goods_item.order_detail || {}).goods_id
                }}</span
              >
            </div>
          </td>
          <!-- 英文产品名称 -->
          <td>
            <div class="infoBox" style="width: 180px">
              <el-popover placement="right" width="400" trigger="click">
                {{ (goods_item.order_detail || {}).goods_title }}
                <span slot="reference">
                  <span style="cursor: pointer;" class="goodsTitleBox">
                    {{ (goods_item.order_detail || {}).goods_title }}
                  </span>
                </span>
              </el-popover>
            </div>
          </td>
          <!-- 产品属性 -->
          <td>
            <div class="infoBox">
              <el-popover placement="bottom" trigger="hover">
                <div class="goodsDetailAll">
                  <div
                    class="goodsDetailAllOneBox"
                    v-for="(detailItem, detailIndex) in (
                      goods_item.order_detail || {}
                    ).detail"
                    :key="detailIndex"
                    :title="detailItem.key + ':' + detailItem.value"
                  >
                    <h1 class="detailTitle">{{ detailItem.key }}</h1>
                    <p>
                      <span class="detailBody">{{ detailItem.value }}</span>
                    </p>
                  </div>
                </div>
                <div class="detailOptBox" slot="reference" style="width: 208px">
                  <p
                    class="detailOpt"
                    v-for="(detailItem, detailIndex) in (
                      goods_item.order_detail || {}
                    ).detail"
                    :key="detailIndex"
                    :title="detailItem.key + ':' + detailItem.value"
                  >
                    {{ detailItem.key }}
                    :{{ detailItem.value }}
                  </p>
                </div>
              </el-popover>
            </div>
          </td>
          <!-- 每包数量 -->
          <td>
            <div class="infoBox" style="width: 100px">
              {{ (goods_item || {}).num }}
            </div>
          </td>
          <!-- 单价 -->
          <td>
            <div class="infoBox" style="width: 100px">
              {{ $fun.RMBNumSegmentation($fun.ceil(goods_item.price)) }}
            </div>
          </td>
          <!-- 小计 -->
          <td>
            <div class="infoBox">
              {{ $fun.RMBNumSegmentation($fun.ceil(goods_item.total)) }}
            </div>
          </td>
        </tr>
      </table>

      <!-- 箱子信息 -->
      <div class="tableBottomInfoBox">
        <div class="flex acenter gap45">
          <div class="logisticsInfoBox">
            <label>{{ $fanyi("重量（Kg）") }}: </label>
            <span>{{ porder_freight_item.weight }}</span>
          </div>
          <div class="logisticsInfoBox">
            <label>{{ $fanyi("体积") }}(m³): </label>
            <span>{{ $fun.ceil(porder_freight_item.volume) }}</span>
          </div>
          <div class="logisticsInfoBox">
            <label>{{ $fanyi("长度") }}(cm): </label>
            <span>{{ porder_freight_item.length }}</span>
          </div>
          <div class="logisticsInfoBox">
            <label>{{ $fanyi("宽度") }}(cm): </label>
            <span>{{ porder_freight_item.width }}</span>
          </div>
          <div class="logisticsInfoBox">
            <label>{{ $fanyi("高度") }}(cm): </label>
            <span>{{ porder_freight_item.height }}</span>
          </div>
        </div>

        <!-- v-if="numberOfStores.goods + replaceProductNum > 2" -->

        <div
          v-if="
            porder_freight_item.porder_freight_detail &&
              porder_freight_item.porder_freight_detail.length > 2
          "
          class=" showAllBtn"
          @click="
            porder_freight_item.showAllGoods = !porder_freight_item.showAllGoods;
            $forceUpdate();
          "
        >
          <span>{{ $fanyi("部署") }}</span>
          <img
            class="jiahaoIcon"
            v-if="!porder_freight_item.showAllGoods"
            :src="require('@/assets/icon/jiahao_fill.svg')"
          />
          <img
            class="jiahaoIcon"
            v-else
            :src="require('@/assets/icon/jianhao.svg')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showAllGoods: false,
      host: location.host,
    };
  },
  components: {},
  computed: {
    porder_freight() {
      return this.$parent.porder_freight || [];
    },
    showButton() {
      if (Array.isArray(this.porder_freight) && this.porder_freight.length) {
        return this.porder_freight.some(
          (item) =>
            item.porder_freight_detail && item.porder_freight_detail.length > 2
        );
      } else {
        return false;
      }
    },
  },
  created() {},
  methods: {
    changeShowAllGoods() {
      this.showAllGoods = !this.showAllGoods;
      this.porder_freight.forEach((element) => {
        element.showAllGoods = this.showAllGoods;
      });
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.modelMain {
  width: $pageWidth;
  margin: 20px auto;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e1e1e1;
  padding: 30px;
}

.modelTitle {
  @extend .dip;
  justify-content: space-between;
  margin-bottom: 25px;
  h2 {
    font-size: 18px;
    font-weight: bold;
  }
}

.boxNumBox {
  @extend .dip;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  width: 200px;
  height: 42px;
  background: #eef5fe;
  border-radius: 6px;
  margin-bottom: 12px;
  .numCor {
    color: #1a73e8;
  }
}

.oneGoodsBox {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.listTableHtmlBox {
  tr {
    border: #ededed solid 1px;
    th {
      border: none;
      font-weight: 400;
      height: 56px;
      padding: 5px 10px;
    }
    td {
      border: none;
      .infoBox {
        min-height: 80px;
        @extend .dip;
        span {
          width: 100%;
          word-break: break-all;
        }
      }
    }
    td:first-child,
    th:first-child {
      padding-left: 20px;
    }
    td:last-child,
    th:last-child {
      padding-right: 20px;
    }
  }
}
.tableBottomInfoBox {
  @extend .jsb;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #e1e1e1;
  padding: 0 25px 0 20px;

  .gap45 {
    gap: 45px;
  }
  .logisticsInfoBox {
    font-weight: 500;
    font-size: 16px;
    span {
      color: #ffa724;
    }
  }
}

.showAllBtn {
  @extend .dip;
  cursor: pointer;
  span {
    font-weight: 400;
    font-size: 14px;
    color: #1a289d;
    margin-right: 10px;
  }
  img {
    width: 24px;
  }
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}

.aLink {
  color: #1e2997;
  text-decoration: none;
}

.goodsTitleBox {
  @extend .text-overflow;
  -webkit-line-clamp: 3;
}
</style>
