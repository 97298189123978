<template>
  <div class="optionBox">
    <el-dialog
      title=" "
      :visible.sync="show"
      width="527px"
      :show-close="false"
      :before-close="saveOrderOption"
    >
      <div class="diaCon">
        <div class="closeBtn" @click="saveOrderOption">
          <i class="el-icon-close"></i>
        </div>
        <div class="modelH1">
          {{ $fanyi("要求或增加额外服务") }}
        </div>
        <div class="modelTipBox">
          {{ $fanyi("专家代理将与您联系，审查您的申请，并在必要时澄清细节。") }}
        </div>
        <div class="vSBox">
          <div class="outLine" :class="{ dontcanEdit: !canEdit }">
            <el-collapse
              class="requirementTypeSelection"
              v-model="openelcollapse"
            >
              <el-collapse-item name="dsf">
                <template slot="title">
                  <div class="requirementTypeSelectionHead">
                    <div class="requirementTypeSelectionHead_tip">
                      {{ $fanyi("需求类型") }}
                    </div>
                    <div class="requirementTypeSelectionHead_chiose">
                      {{
                        (
                          selectList.find((ioo) => {
                            return ioo.value == parentData.requirement_type;
                          }) || { label: parentData.requirement_type }
                        ).label
                      }}
                    </div>
                    <img
                      :src="require('@/assets/icon/arrow-down.png')"
                      alt=""
                      v-if="canEdit"
                      class="arrowIcon"
                    />
                  </div>
                </template>
                <div class="selectListBox">
                  <div
                    class="selectOpt"
                    v-for="(item, index) in selectList"
                    :key="index"
                    v-show="parentData.requirement_type != item.value"
                    @click="setrequirement_type(item.value)"
                  >
                    {{ item.label }}
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <textarea
            :class="{ dontcanEdit: !canEdit }"
            class="describeBox"
            v-model="parentData.describe"
            :placeholder="$fanyi('描述')"
          >
          </textarea>
          <div class="optionListBox" :class="{ dontcanEdit: !canEdit }">
            <div
              class="optionOpt"
              v-for="(optionListitem, index) in $parent.$parent.optionList"
              :key="'optionsList' + index"
              @click="chioseIntroduction(optionListitem)"
            >
              <div class="headBox">
                <input
                  type="checkbox"
                  class="optionCheckInput"
                  :checked="optionListitem.checked"
                  @click="chooseOption(optionListitem)"
                />
                <span
                  class="optionNameBox"
                  @click="chooseOption(optionListitem)"
                  >{{ optionListitem.name_translate }}</span
                >
                <el-popover ref="poep1" placement="top" trigger="hover">
                  <div class="optionPrement">
                    <h3>
                      {{ $fanyi("标签为亚马逊FBA") }}
                    </h3>
                    <p>
                      {{
                        $fanyi(
                          "亚马逊FBA的标签是免费的，只要程序是由制造商负责。"
                        )
                      }}
                    </p>
                    <p>
                      {{
                        $fanyi(
                          "如果程序是由Rakumart执行的，则将收取“SKU标签”服务费用。"
                        )
                      }}
                    </p>
                  </div>
                  <img
                    v-show="
                      optionListitem.name_translate == 'Etiquetado Amazon FBA'
                    "
                    slot="reference"
                    :src="require('@/assets/icon/info_fill.svg')"
                    alt=""
                  />
                </el-popover>
                <el-popover ref="pop2" placement="top" trigger="hover">
                  <div class="optionPrement">
                    <h3>
                      {{ $fanyi("亚马逊FBA套装包装") }}
                    </h3>
                    <p>
                      {{
                        $fanyi(
                          "为亚马逊FBA免费打包产品，只要程序由制造商负责。"
                        )
                      }}
                    </p>
                    <p>
                      {{
                        $fanyi(
                          "如果该程序是由Rakumart执行的，则将收取“捆绑”服务费用。"
                        )
                      }}
                    </p>
                  </div>
                  <img
                    v-show="
                      optionListitem.name_translate ==
                        'Empaquetado conjunto Amazon FBA'
                    "
                    slot="reference"
                    :src="require('@/assets/icon/info_fill.svg')"
                    alt=""
                  />
                </el-popover>
              </div>
              <div class="conBox">
                <el-input-number
                  size="mini"
                  :min="0"
                  @input="setNumber($event, optionListitem)"
                  @change="$parent.$parent.setOneGoodsOptionTotalPrice()"
                  v-model="optionListitem.num"
                ></el-input-number>
                <ul class="conOpt">
                  <li class="title">{{ $fanyi("单价") }}</li>
                  <li class="price">
                    <span
                      >{{
                        $fun.EURNumSegmentation(
                          $fun.ceil(
                            optionListitem.price *
                              $parent.$parent.datas.exchange_rate
                          )
                        )
                      }}€</span
                    >
                    <span
                      >({{
                        $fun.RMBNumSegmentation(optionListitem.price)
                      }}￥)</span
                    >
                  </li>
                </ul>
                <ul class="conOpt zongJia">
                  <li class="title">{{ $fanyi("总价") }}</li>
                  <li class="price">
                    <span
                      >{{
                        $fun.EURNumSegmentation(
                          $fun.ceil(
                            optionListitem.price *
                              optionListitem.num *
                              $parent.$parent.datas.exchange_rate
                          )
                        )
                      }}€</span
                    >
                    <span
                      >({{
                        $fun.RMBNumSegmentation(
                          optionListitem.price * optionListitem.num
                        )
                      }}￥)</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="showVideo" v-if="show_showVideo_box">
        <h1>{{ active_name_translate }}</h1>
        <el-carousel
          height="225px"
          style=" width: 400px;"
          indicator-position="none"
          :autoplay="false"
          :arrow="activeImage && activeVideo ? 'always' : 'never'"
        >
          <el-carousel-item v-if="activeImage">
            <img :src="activeImage" style="width: 100%;height: 100%;" alt="" />
          </el-carousel-item>
          <el-carousel-item v-if="activeVideo">
            <iframe
              style="width: 100%;height: 100%;"
              :src="activeVideo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import odata from "@/utlis/allImportData.js";
export default {
  data() {
    return {
      show: false,
      // selectChiose: "Personalización de producto",
      selectList: [
        {
          label: "Personalización de producto",
          value: "Personalización de producto",
        },
        {
          label: "Packaging personalizado",
          value: "Packaging personalizado",
        },
        {
          label: "Embalaje especial",
          value: "Embalaje especial",
        },
        {
          label: "Otros",
          value: "Otros",
        },
      ],
      parentData: {}, //父组件数据
      openelcollapse: "",
      optionIntroduction: odata.optionIntroduction,
      activeVideo: "", //展示的视频
      activeImage: "", //展示的图片
      active_name_translate: "",
      show_showVideo_box: false,
    };
  },
  components: {},
  computed: {
    canEdit() {
      let orderstatus = this.$parent.$parent.orderStatus;
      return orderstatus == "temporary" || orderstatus == "editing";
    },
  },
  created() {},
  methods: {
    // 选择option
    chooseOption(in_optionListitem) {
      in_optionListitem.checked = !in_optionListitem.checked;
      this.$parent.$parent.setOptionsNum(
        in_optionListitem,
        this.parentData.num
      );
      this.$parent.$parent.setOneGoodsOptionTotalPrice();
    },

    // 选择option介绍
    chioseIntroduction(in_optionListitem) {
      let find_data =
        this.optionIntroduction.find((ii) => ii.id == in_optionListitem.id) ||
        {};
      this.activeImage = find_data.image;
      this.activeVideo = find_data.video;
      this.active_name_translate = in_optionListitem.name_translate;
      // 点击的option已选中并且有图片或者视频的时候展示option介绍
      let option_hadChiose =
        in_optionListitem.checked && (find_data.image || find_data.video);
      this.show_showVideo_box = !!option_hadChiose;
    },

    setrequirement_type(value) {
      this.parentData.requirement_type = value;
      this.openelcollapse = "";
      this.$forceUpdate();
    },

    open(data) {
      this.parentData = data;
      this.show_showVideo_box = false;
      this.show = true;
    },

    // 设置附加服务-保存选择的附加服务
    saveOrderOption(done) {
      if (!this.canEdit) return (this.show = false);
      let haveChiose = false; //用户是否有选择附加服务
      this.$parent.$parent.checkItem.option = [];
      for (var i in this.$parent.$parent.optionList) {
        if (this.$parent.$parent.optionList[i].checked == true) {
          this.$parent.$parent.checkItem.option.push(
            this.$parent.$parent.optionList[i]
          );
          haveChiose = true;
        }
      }
      // 如果过选择了附加服务,就比选择服务类型
      if (
        (haveChiose || this.parentData.describe) &&
        !this.parentData.requirement_type
      ) {
        return this.$message.error(this.$fanyi("请选择具体的附加服务类型"));
      }
      this.parentData.option = JSON.parse(
        JSON.stringify(this.$parent.$parent.checkItem.option)
      );
      this.parentData.accessorialService = false;
      this.show = false;
      this.$parent.$parent.calculateOrder();
    },

    // 设置不能为0
    setNumber(neow, optionListitem) {
      if (neow == undefined) {
        optionListitem.num = 1;
        this.$forceUpdate();
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
/deep/.el-dialog {
  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      top: 15px;
      right: 15px;
    }
  }
  .el-dialog__body {
    padding: 15px;
    position: relative;
    .diaCon {
      padding: 25px 35px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .closeBtn {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 20px;
        height: 20px;
        font-size: 20px;
        cursor: pointer;
      }
      .modelH1 {
        font-size: 18px;
        color: #282828;
        line-height: 18px;
        text-align: center;
        line-height: 1;
        margin-bottom: 10px;
      }
      .modelTipBox {
        font-size: 14px;
        color: #979797;
        line-height: 22px;
        width: 380px;
        text-align: center;
        margin-bottom: 30px;
      }
      .vSBox {
        max-height: 65vh;
        overflow: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        .outLine {
          height: 72px;
          margin-bottom: 13px;
          .requirementTypeSelection {
            width: 412px;
            border: 2px solid #dbd7d6;
            border-radius: 6px;
            overflow: hidden;
            position: relative;
            .arrowIcon {
              position: absolute;
              width: 12px;
              height: 7px;
              top: 22px;
              right: 22px;
            }
            .requirementTypeSelectionHead {
              padding: 15px;
              .requirementTypeSelectionHead_tip {
                font-size: 14px;
                color: #979797;
                line-height: 1;
                margin-bottom: 3px;
              }
              .requirementTypeSelectionHead_chiose {
                font-size: 14px;
                color: #262626;
                line-height: 22px;
                height: 22px;
              }
            }
            .selectListBox {
              padding: 0px 15px;
              .selectOpt {
                cursor: pointer;
                padding: 10px 0;
                font-size: 14px;
                color: #262626;
                line-height: 22px;
              }
            }
          }
        }
        .describeBox {
          width: 412px;
          height: 143px;
          flex: 0 0 143px;
          border: 2px solid #dbd7d6;
          border-radius: 6px;
          padding: 10px 15px;
          resize: none;
          margin-bottom: 15px;
        }
        .optionListBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          padding: 0 45px;
          .optionOpt {
            width: 417px;
            height: 89px;
            background: #ffffff;
            border: 1px solid #e9e9e9;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 15px;
            margin-bottom: 13px;
            cursor: pointer;
            .headBox {
              height: 15px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 15px;
              .optionCheckInput {
                border: 1px solid #202a93;
                border-radius: 3px;
                margin-right: 9px;
              }
              .optionNameBox {
                cursor: pointer;
              }
              img {
                margin-left: 6px;
              }
            }
            .conBox {
              display: flex;
              justify-content: center;
              align-items: center;

              .conOpt {
                flex: 1;
                li {
                  color: #282828;
                  text-align: center;
                }
                .title {
                  font-size: 8px;
                }
                .price {
                  font-size: 12.22px;
                }
              }
              .conOpt.zongJia {
                text-align: right;
                padding-right: 10px;
              }
              /deep/.el-input-number {
                margin-right: 40px;
              }
            }
          }
        }
      }
    }
    .showVideo {
      flex: 0 0 460px;
      padding: 20px 30px;
      position: absolute;
      left: 100%;
      bottom: 0;
      transform: translate(20px, 0px);
      width: 460px;
      background: #ffffff;
      border-radius: 4px;
      h1 {
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: #000000;
        margin-bottom: 18px;
      }
      .el-carousel__item {
        border-radius: 12px;
      }
    }
  }
}
/deep/.el-collapse-item__arrow {
  display: none;
}
/deep/.el-collapse-item__header {
  border-bottom: none;
  height: 72px;
  background-color: transparent;
}
/deep/.el-collapse-item {
  border-radius: 6px;
}
/deep/.el-collapse-item__content {
  padding-bottom: 10px;
}
.optionPrement {
  padding: 18px;
  margin: 0 !important;
  width: 310px;
  h3 {
    text-align: left;
    font-size: 18px;
    line-height: 22.84px;
    font-weight: SemiBold;
    color: #2f2f2f !important;
    margin-bottom: 20px;
  }
  p {
    text-align: left;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 22px;
    color: black !important;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.dontcanEdit {
  pointer-events: none;
}
</style>
