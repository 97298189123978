<template>
  <div class="modeOofTransportation">
    <whyDia2Vue ref="whyDia2"></whyDia2Vue>
    <div class="paymentPlanSelection">
      <div
        class="paymentPlanSelectionChiose"
        :class="{ onRight: $parent.pay_logistics_fees == 0 }"
      ></div>
      <div
        class="paymentPlanSelectionopt"
        @click="
          if ($parent.orderStatus != 'obligation') return;
          $parent.pay_logistics_fees = 1;
          orderOfferLogistics.length ? (userActOpt = 0) : '';
          $parent.saveSelectedLogisticsInfo();
        "
      >
        {{ $fanyi("产品及运输") }}
        <el-popover ref="pop1" placement="top-start" trigger="click">
          <div class="optionDescription">
            <div class="con">
              <h1>{{ $fanyi("产品付款及运输") }}</h1>
              <p>{{ $fanyi("选择此选项支付产品和运输。") }}</p>
              <p>
                {{
                  $fanyi(
                    "我们向工厂下订单，一旦质量控制通过，货物就开始国际运输。"
                  )
                }}
              </p>
            </div>
          </div>
          <img
            @click.stop="closeOther(1)"
            slot="reference"
            :src="require('@/assets/icon/info_fill.svg')"
            alt=""
          />
        </el-popover>
      </div>
      <div
        class="paymentPlanSelectionopt"
        @click="
          if ($parent.orderStatus != 'obligation') return;
          $parent.pay_logistics_fees = 0;
          userActOpt = -1;
          $parent.saveSelectedLogisticsInfo();
        "
      >
        {{ $fanyi("只有产品") }}
        <el-popover ref="pop2" placement="top-start" trigger="click">
          <div class="optionDescription">
            <div class="con">
              <h1>{{ $fanyi("仅支付产品") }}</h1>
              <p>{{ $fanyi("如果您只想支付产品的成本，请选择此选项。") }}</p>
              <p>
                {{
                  $fanyi(
                    "运费报价不包括在内，一旦货物进入我们的仓库，您可以通过自己的货运代理管理运输。"
                  )
                }}
              </p>
              <p>
                {{
                  $fanyi("你也可以统一不同订单的货物，或者在另一个时间发货。")
                }}
              </p>
            </div>
          </div>
          <img
            @click.stop="closeOther(2)"
            slot="reference"
            :src="require('@/assets/icon/info_fill.svg')"
            alt=""
          />
        </el-popover>
      </div>
    </div>
    <div class="zhidu">
      <div class="yunshuxuanze">
        <span>{{ $fanyi("选择装运的运输类型") }}</span>
      </div>

      <div class="optBox">
        <div
          v-for="(fangshiItem, fangShiIndex) in orderOfferLogistics"
          :key="fangShiIndex"
          class="modeOofTransportationOpt"
          :class="{
            userActOpt: userActOpt == fangShiIndex,
          }"
        >
          <div class="modeOofTransportationOptCon">
            <h1
              :class="{
                disDiv: orderOfferLogistics[fangShiIndex].is_reached != 1,
              }"
              @click="
                chioseOpt(fangShiIndex, fangshiItem);
                if (
                  $parent.orderStatus != 'obligation' ||
                  orderOfferLogistics[fangShiIndex].is_reached != 1
                )
                  return;
                $parent.pay_logistics_fees == 1
                  ? $parent.saveSelectedLogisticsInfo()
                  : '';
              "
            >
              <div class="activeIcon" v-if="$parent.pay_logistics_fees == 1">
                <div></div>
              </div>
              <span v-if="fangshiItem.logistics">{{
                $fanyi(fangshiItem.logistics.name)
              }}</span>
              <i class="el-icon-arrow-down"></i>
              <div
                class="tuiJianIcon"
                v-if="orderOfferLogistics[fangShiIndex].is_recommended == 1"
              >
                <span>{{ $fanyi("最佳选择") }}</span>
              </div>
            </h1>
            <div class="importPrice">
              <p>
                {{ $fanyi("运费") }}:
                <font class="priceNum" v-if="fangshiItem.logistics"
                  >{{ $fun.EURNumSegmentation(fangshiItem.sumPrice) }}€
                </font>
              </p>
              <p>
                {{ $fanyi("预计交货时间") }}:
                <font class="priceNum" v-if="fangshiItem.logistics">
                  {{
                    fangshiItem.logistics.useful_rule
                      ? fangshiItem.logistics.useful_rule.predict_duration
                      : ""
                  }}
                  días</font
                >
              </p>
            </div>
            <div class="transportationDetail">
              <h2>{{ $fanyi("进口总成本的成本细目。") }}</h2>
              <p>
                <span>{{ $fanyi("国际运输") }}</span>
                <font class="transportationPrice">
                  <span
                    >{{
                      $fun.EURNumSegmentation(
                        fangshiItem.international_freight
                      )
                    }}€</span
                  >
                  ({{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(
                        fangshiItem.international_freight /
                          $parent.datas.exchange_rate
                      )
                    )
                  }}￥)
                </font>
              </p>
              <p>
                <span>{{ $fanyi("西班牙的运费") }}</span>
                <font class="transportationPrice">
                  <span
                    >{{
                      $fun.EURNumSegmentation(
                        fangshiItem.foreign_express_price
                      )
                    }}€ </span
                  >({{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(
                        fangshiItem.foreign_express_price /
                          $parent.datas.exchange_rate
                      )
                    )
                  }}￥)
                </font>
              </p>
              <p>
                <span>{{ $fanyi("反倾销税") }}</span>
                <font class="transportationPrice">
                  <span
                    >{{
                      $fun.EURNumSegmentation(fangshiItem.clearance_price)
                    }}€</span
                  >
                  ({{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(
                        fangshiItem.clearance_price /
                          $parent.datas.exchange_rate
                      )
                    )
                  }}￥)
                </font>

                <span v-if="fangshiItem.clearance_price == 0" class="red"
                  >*Rakumart se encarga del despacho de aduanas y el costo está
                  incluido en el envío.</span
                >
              </p>
              <p>
                <span>{{ $fanyi("增值税") }}</span>
                <font class="transportationPrice">
                  <span
                    >{{
                      $fun.EURNumSegmentation(fangshiItem.added_price)
                    }}€ </span
                  >({{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(
                        fangshiItem.added_price / $parent.datas.exchange_rate
                      )
                    )
                  }}￥)
                </font>
                <span v-if="fangshiItem.added_price == 0" class="red"
                  >*Operación sujeta a inversión del sujeto pasivo según el
                  artículo 84.1 de la Ley 37/1992 del IVA</span
                >
              </p>
              <!-- <p
                v-if="
                  fangshiItem.personal_added_price &&
                    fangshiItem.personal_added_price != '0.00'
                "
              >
                <span>{{ $fanyi("个人所得税") }}</span>
                <font class="transportationPrice">
                  <span
                    >{{
                      $fun.EURNumSegmentation(fangshiItem.personal_added_price)
                    }}€ </span
                  >({{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(
                        fangshiItem.personal_added_price /
                          $parent.datas.exchange_rate
                      )
                    )
                  }}￥)
                </font>
              </p> -->
              <p>
                <b>{{ $fanyi("总运费") }}</b>
                <font class="transportationPrice">
                  <b
                    >{{ $fun.EURNumSegmentation(fangshiItem.sumPrice) }}€({{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          fangshiItem.sumPrice / $parent.datas.exchange_rate
                        )
                      )
                    }}￥)
                  </b>
                </font>
              </p>
            </div>
          </div>
          <transition name="fade">
            <div
              class="qiPiLiangTiShi"
              v-if="
                orderOfferLogistics[fangShiIndex].reached_remark &&
                  orderOfferLogistics[fangShiIndex].is_reached == 0
              "
              v-show="showTiShi[fangShiIndex]"
            >
              <div class="icon">i</div>
              <span
                >{{ orderOfferLogistics[fangShiIndex].reached_remark }}
              </span>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import whyDia2Vue from "./whyDia2.vue";
export default {
  data() {
    return {
      userActOpt: -1,
      config: {
        top: "20vh",
        width: "822px",
        title: this.$fanyi("为什么现在估计装运?"),
        btnTxt: [this.$fanyi("已理解")],
      },
      showTiShi: [],
    };
  },
  components: { whyDia2Vue },
  computed: {
    orderOfferLogistics() {
      //        orderOfferLogistics参数：
      //        is_recommended 是否是推荐, 默认0-不推荐,1-推荐
      //        is_reached   是否达到起批量,0-未达到,1-达到
      //        reached_remark 起批量备注
      // console.log(this.$parent.datas.orderOfferLogistics);
      let returnData = this.$parent.datas.orderOfferLogistics;
      // 将推荐的物流方式排在前面;
      if (returnData) {
        returnData.sort(function(a, b) {
          return b.is_recommended - a.is_recommended;
        });
        this.showTiShi = [];
        returnData.forEach((returnDataItem) => {
          this.showTiShi.push(false);
        });
      }

      return this.$parent.datas.orderOfferLogistics;
    },
  },
  created() {},
  mounted() {
    // 如果用户选择了物流方式就显示用户选择的物流方式
    if (this.$parent.logistics) {
      // 获取选中的物流id在物流方式列表中的位置,并在组件中选中它
      let chioseIndex = this.orderOfferLogistics.findIndex((item) => {
        return item.logistics_id == this.$parent.logistics;
      });
      this.chioseOpt(chioseIndex, this.orderOfferLogistics[chioseIndex]);
    } else if (
      // 否则如果第一个选项达到起批量就默认选择第一个选项
      this.orderOfferLogistics &&
      this.orderOfferLogistics[0] &&
      this.orderOfferLogistics[0].is_reached == 1
    ) {
      this.chioseOpt(0, this.orderOfferLogistics[0]);
    }
  },
  methods: {
    // 选择物流选项
    chioseOpt(i, item) {
      if (item.is_reached != 1) {
        this.showTiShi[i] = true;
        setTimeout(() => {
          this.showTiShi[i] = false;
          this.$forceUpdate();
        }, 2000);
        this.$forceUpdate();
        return;
      }
      //console.log("选择" + i);
      // if (this.userActOpt == i) {
      //   this.userActOpt = -1;
      // } else {
      this.userActOpt = i;
      // }
    },
    // 关闭另一个弹窗
    closeOther(num) {
      if (num == 1) {
        this.$refs.pop2.doClose();
      } else {
        this.$refs.pop1.doClose();
      }
    },
    // 打开常见问题弹窗
    opentroubleShootingDia() {
      this.$refs.whyDia2.open();
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../../../../../css/mixin";

.modeOofTransportation {
  width: $pageWidth;
  margin: 20px auto 0;

  .zhidu {
    position: relative;

    .zhezhao {
      position: absolute;
      background-color: rgba($color: #f6f6f6, $alpha: 0.7);
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }

  .paymentPlanSelection {
    width: 329px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 3px 23px;

    .paymentPlanSelectionopt {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      cursor: pointer;
      color: #000c37;
      font-weight: 600;
      font-size: 12px;
      height: 100%;

      img {
        width: 14px;
        height: 14px;
        margin-left: 10px;
        transform: translateY(3px);
      }
    }

    $paymentPlanSelectionChiose: 157px;

    .paymentPlanSelectionChiose {
      width: $paymentPlanSelectionChiose;
      height: 40px;
      background: #e6f2fe;
      box-shadow: 0px 1.32px 1.98px 0px rgba(0, 0, 0, 0.07);
      border-radius: 4px;
      position: absolute;
      left: 5px;
      transition: 0.3s;
    }

    .paymentPlanSelectionChiose.onRight {
      left: $paymentPlanSelectionChiose + 8px;
    }
  }

  .yunshuxuanze {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 4px 20px;

    > span {
      font-size: 18px;
      font-weight: 600;
      color: #2f2f2f;
      line-height: 18px;
    }

    .troubleShooting {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        margin-right: 5px;
      }

      span {
        font-size: 13px;
        font-weight: 400;
        text-decoration: underline;
        color: #1c2899;
        line-height: 18px;
      }
    }
  }

  .optBox {
    margin: 0 4px;
    display: flex;
    flex-wrap: wrap;

    .modeOofTransportationOpt {
      width: 324px;
      margin-right: 28px;

      &:nth-child(4n) {
        margin-right: 0;
      }

      .modeOofTransportationOptCon {
        max-height: 78px;
        transition: 0.3s;
        border: 1px solid #e8e8e8;
        background: #ffffff;
        border-radius: 6px;
        overflow: hidden;
      }

      .actIcon {
        position: absolute;
        padding: 2px 20px;
        background: #00187c;
        border-radius: 6px 0px 6px 0px;
        color: white;
        text-align: center;
        font-size: 12px;
        margin-left: -1px;
        margin-top: -1px;
      }

      h1 {
        height: 78px;
        display: flex;
        align-items: center;
        padding: 0 38px 0 34px;
        cursor: pointer;
        position: relative;

        .activeIcon {
          width: 16px;
          height: 16px;
          background: #ffffff;
          border: 1px solid #e8e8e8;
          border-radius: 50%;
          position: relative;
          margin-right: 10px;

          div {
            display: none;
          }
        }

        > span {
          font-size: 17px;
          font-weight: 600;
          color: #2f2f2f;
          line-height: 23px;
        }

        .el-icon-arrow-down {
          margin-left: auto;
          font-weight: bold;
          transition: 0.3s;
        }

        &.disDiv {
          > span {
            color: #d4d4d4;
          }

          .el-icon-arrow-down {
            display: none;
          }
        }

        .tuiJianIcon {
          border-radius: 6px 0px 6px 0px;
          background: #00187c !important;
          width: 113px;
          height: 18px;
          font-weight: bold;
          color: #fffefe;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;

          span {
            font-size: 12px;
            transform: scale(0.75);
          }
        }
      }

      .importPrice {
        padding: 0 19px 15px 19px;

        p {
          line-height: 23px;
          height: 15px;
          font-size: 13px;
          font-weight: 400;
          margin-bottom: 15px;

          .priceNum {
            font-weight: 600;
          }
        }
      }

      .transportationDetail {
        padding: 0 19px 0px;

        h2 {
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
          line-height: 17px;
          min-height: 71px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          gap: 7px;
          align-items: flex-start;
          justify-content: center;
          border-bottom: solid 1px #e8e8e8;
          padding: 20px 0;

          &:last-child {
            border-bottom: none;
            color: black;
          }

          color: #868686;

          .transportationPrice {
            color: #2f2f2f;
          }

          .red {
            font-size: 12px;
          }
        }
      }

      @keyframes mymove {
        from {
          opacity: 0;
          position: relative;
          top: -20px;
        }

        to {
          opacity: 1;
          position: relative;
          top: 0px;
        }
      }
    }

    .qiPiLiangTiShi {
      width: 445px;
      background: #ffdfe1;
      border: 1px solid #e8e8e8;
      opacity: 0.75;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      animation: mymove 0.7s;
      padding: 10px;
      margin: 15px auto;

      .icon {
        width: 13px;
        height: 13px;
        display: inline-block;
        background: #ff6271;
        border-radius: 50%;
        color: white;
        font-weight: 400;
        font-size: 10px;
        text-align: center;
        line-height: 16px;
      }

      span {
        display: inline-block;
        max-width: 400px;
        margin-left: 5px;
        flex-wrap: wrap;
        font-size: 14px;
        font-weight: 400;
        color: #ff6271;
        line-height: 20px;
      }
    }

    .modeOofTransportationOpt.userActOpt {
      max-height: 800px;

      .modeOofTransportationOptCon {
        max-height: 800px;
        border: 2px solid #1c2899;
      }

      h1 {
        height: 78px;
        display: flex;
        align-items: center;
        padding: 0 31px 0 19px;
        position: relative;

        .activeIcon {
          width: 16px;
          height: 16px;
          background: #ffffff;
          border: 1px solid #1c2899;
          border-radius: 50%;
          position: relative;
          margin-right: 10px;

          div {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 8px;
            height: 8px;
            background: #1c2899;
            border: 1px solid #1c2899;
            border-radius: 50%;
          }
        }

        > span {
          font-size: 18px;
          font-weight: 600;
          color: #1c2899;
          line-height: 23px;
        }

        .el-icon-arrow-down {
          margin-left: auto;
          font-weight: bold;
          transform: rotate(180deg);
        }
      }
    }
  }
}

.tishiCon {
  width: 733px;
  margin: 0 auto;
  height: 218px;
  font-size: 19px;

  font-weight: 400;
  color: #2f2f2f;
  line-height: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-1rem);
}

.optionDescription {
  // transform: scale(0.65);
  zoom: 0.75;
  padding: 8px;

  .con {
    width: 280px;

    * {
      line-height: 22.84px;
      color: #2f2f2f;
    }

    h1 {
      margin-bottom: 25px;
      font-size: 18px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
      margin-bottom: 25px;
      text-align: left;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
